<template>
  <div class="tab">
    <div class="tab-left">
      <div class="tab-title">
        <span>选择患者</span>
      </div>
      <div class="tab-search">
        <div class="item-search">
          <span class="span">床位</span>
          <el-input v-model="input" placeholder="请输入床位"></el-input>
        </div>
        <div class="item-search">
          <span class="span">姓名</span>
          <el-input v-model="input" placeholder="请输入姓名"></el-input>
        </div>
      </div>
      <div class="tab-table">
        <el-table :data="tableData" stripe border height="100%">
          <el-table-column type="selection" width="55">
          </el-table-column>
          <el-table-column prop="name" label="床位">
          </el-table-column>
          <el-table-column prop="address" label="姓名">
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div class="tab-right">
      <div class="tab-position">
        <el-button type="primary">打印</el-button>
      </div>
      <div class="tab-scroll">
        <div class="tab-line">
          <div class="tab-key">
            <span>入院方式</span>
          </div>
          <el-radio-group v-model="radio">
            <el-radio label="1">门诊</el-radio>
            <el-radio label="2">急诊</el-radio>
            <el-radio label="3">步行</el-radio>
            <el-radio label="4">轮椅</el-radio>
            <el-radio label="5">平车</el-radio>
          </el-radio-group>
        </div>
        <div class="tab-line">
          <div class="tab-key">
            <span>教育</span>
          </div>
          <el-radio-group v-model="radio">
            <el-radio label="1">文盲</el-radio>
            <el-radio label="2">小学</el-radio>
            <el-radio label="3">初中</el-radio>
            <el-radio label="4">高中</el-radio>
            <el-radio label="5">大专</el-radio>
            <el-radio label="6">本科</el-radio>
            <el-radio label="7">研究生</el-radio>
          </el-radio-group>
        </div>
        <div class="tab-line">
          <div class="tab-key">
            <span>宗教</span>
          </div>
          <el-radio-group v-model="radio">
            <el-radio label="1">无</el-radio>
            <el-radio label="2">有</el-radio>
          </el-radio-group>
        </div>
        <div class="tab-line">
          <div class="tab-key">
            <span>过敏史</span>
          </div>
          <el-radio-group v-model="radio">
            <el-radio label="1">无</el-radio>
            <el-radio label="2">有</el-radio>
          </el-radio-group>
        </div>
        <div class="tab-line">
          <div class="tab-key">
            <span>嗜好</span>
          </div>
          <div class="tab-key1">
            <span>吸烟</span>
          </div>
          <el-radio-group v-model="radio">
            <el-radio label="1">无</el-radio>
            <el-radio label="2">有</el-radio>
          </el-radio-group>
          <div class="tab-key1 tab-key2">
            <span>饮酒</span>
          </div>
          <el-radio-group v-model="radio">
            <el-radio label="1">无</el-radio>
            <el-radio label="2">有</el-radio>
          </el-radio-group>
        </div>
        <div class="tab-line">
          <div class="tab-key">
            <span>既往史</span>
          </div>
          <el-radio-group v-model="radio">
            <el-radio label="1">无</el-radio>
            <el-radio label="2">有</el-radio>
          </el-radio-group>
        </div>
        <div class="tab-line">
          <div class="tab-key tab-key3">
            <span>（A）情绪</span>
          </div>
          <el-radio-group v-model="radio">
            <el-radio label="1">正常</el-radio>
            <el-radio label="2">焦虑</el-radio>
            <el-radio label="3">恐惧</el-radio>
            <el-radio label="4">其他</el-radio>
          </el-radio-group>
        </div>
        <div class="tab-line">
          <div class="tab-key tab-key3">
            <span>（B）意识水平</span>
          </div>
          <el-radio-group v-model="radio">
            <el-radio label="1">清醒</el-radio>
            <el-radio label="2">嗜睡</el-radio>
            <el-radio label="3">昏迷</el-radio>
            <el-radio label="4">其他</el-radio>
          </el-radio-group>
        </div>
        <div class="tab-line">
          <div class="tab-key tab-key3">
            <span>（C）身体状况</span>
          </div>
        </div>
        <div class="tab-line tab-line1">
          <div class="tab-key">
            <span>1.视力</span>
          </div>
          <el-radio-group v-model="radio">
            <el-radio label="1">正常</el-radio>
            <el-radio label="2">异常</el-radio>
          </el-radio-group>
        </div>
        <div class="tab-line tab-line1">
          <div class="tab-key">
            <span>2.听力</span>
          </div>
          <el-radio-group v-model="radio">
            <el-radio label="1">正常</el-radio>
            <el-radio label="2">异常</el-radio>
            <el-radio label="3">其他</el-radio>
          </el-radio-group>
        </div>
        <div class="tab-line tab-line1">
          <div class="tab-key">
            <span>3.语言</span>
          </div>
          <el-radio-group v-model="radio">
            <el-radio label="1">正常</el-radio>
            <el-radio label="2">异常</el-radio>
          </el-radio-group>
        </div>
        <div class="tab-line tab-line1">
          <div class="tab-key">
            <span>4.面容</span>
          </div>
          <el-radio-group v-model="radio">
            <el-radio label="1">正常</el-radio>
            <el-radio label="2">异常</el-radio>
          </el-radio-group>
        </div>
        <div class="tab-line tab-line1">
          <div class="tab-key">
            <span>5.进食</span>
          </div>
          <el-radio-group v-model="radio">
            <el-radio label="1">正常</el-radio>
            <el-radio label="2">异常</el-radio>
          </el-radio-group>
        </div>
        <div class="tab-line tab-line1">
          <div class="tab-key">
            <span>6.排便</span>
          </div>
          <el-radio-group v-model="radio">
            <el-radio label="1">正常</el-radio>
            <el-radio label="2">异常</el-radio>
          </el-radio-group>
        </div>
        <div class="tab-line tab-line1">
          <div class="tab-key">
            <span>7.排尿</span>
          </div>
          <el-radio-group v-model="radio">
            <el-radio label="1">正常</el-radio>
            <el-radio label="2">异常</el-radio>
          </el-radio-group>
        </div>
        <div class="tab-line tab-line1">
          <div class="tab-key">
            <span>8.活动</span>
          </div>
          <el-radio-group v-model="radio">
            <el-radio label="1">正常</el-radio>
            <el-radio label="2">异常</el-radio>
            <el-radio label="3">其他</el-radio>
          </el-radio-group>
        </div>
        <div class="tab-line tab-line1">
          <div class="tab-key">
            <span>9.睡眠</span>
          </div>
          <el-radio-group v-model="radio">
            <el-radio label="1">正常</el-radio>
            <el-radio label="2">异常</el-radio>
          </el-radio-group>
        </div>
        <div class="tab-line tab-line1">
          <div class="tab-key">
            <span>10.自立能力</span>
          </div>
          <el-radio-group v-model="radio">
            <el-radio label="1">能</el-radio>
            <el-radio label="2">不能</el-radio>
            <el-radio label="3">部分</el-radio>
          </el-radio-group>
        </div>
        <div class="tab-line tab-line1">
          <div class="tab-key">
            <span>11.皮肤</span>
          </div>
          <el-radio-group v-model="radio">
            <el-radio label="1">正常</el-radio>
            <el-radio label="2">有压疮危险</el-radio>
            <el-radio label="3">部分</el-radio>
          </el-radio-group>
        </div>
        <div class="tab-line">
          <div class="tab-key tab-key3">
            <span>（D）管路</span>
          </div>
          <el-radio-group v-model="radio">
            <el-radio label="1">无</el-radio>
            <el-radio label="2">有</el-radio>
          </el-radio-group>
        </div>
        <div class="tab-line">
          <div class="tab-key tab-key4">
            <span>（E）跌倒风险评估</span>
          </div>
          <el-radio-group v-model="radio">
            <el-radio label="1">无</el-radio>
            <el-radio label="2">低风险</el-radio>
            <el-radio label="3">高风险</el-radio>
          </el-radio-group>
        </div>
        <div class="tab-line">
          <div class="tab-key tab-key4">
            <span>（F）专家评估</span>
          </div>
          <el-input type="textarea" :rows="2" placeholder="请输入内容" v-model="textarea">
          </el-input>
        </div>
        <div class="tab-line">
          <div class="tab-key4">
            <span>来源</span>
          </div>
          <el-input v-model="input" placeholder="请输入"></el-input>
          <div class="tab-key4">
            <span>签名</span>
          </div>
          <el-input v-model="input" placeholder="请输入姓名"></el-input>
          <div class="tab-key4">
            <span>日期</span>
          </div>
          <el-date-picker v-model="value1" type="date" placeholder="选择日期">
          </el-date-picker>
        </div>
      </div>
      <div class="tab-button">
        <el-button type="primary">保存</el-button>
        <el-button>取消</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      input: '',
      value: '',
      options: [],
      radio: '',
      value1: '',
      textarea: '',
      tableData: [
        {},
        {},
        {}
      ]
    }
  }
}
</script>

<style lang="less" scoped>
.tab {
  display: flex;
  width: 100%;
  height: 100%;

  .tab-left {
    width: 380px;
    height: 100%;
    border-radius: 8px;
    border: 1px solid rgba(242, 242, 242, 1);
    box-shadow: 0px 0px 15px rgba(242, 242, 242, 1);
    box-sizing: border-box;

    .tab-search {
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-wrap: wrap;
      width: 100%;
      height: 60px;

      .item-search {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: calc(50% - 20px);
        height: 50px;
        margin: 0 10px;
      }

      .span {
        margin-right: 10px;
        white-space: nowrap;
        font-size: 16px;
      }
    }

    .tab-title {
      width: 100%;
      height: 50px;
      line-height: 50px;
      text-align: center;
      font-size: 16px;
    }

    .tab-table {
      width: 100%;
      height: calc(100% - 170px);

      /deep/.el-table__cell {
        text-align: center;
      }
    }
  }

  .tab-right {
    position: relative;
    width: calc(100% - 400px);
    height: 100%;
    margin-left: 20px;
    padding: 10px;
    border-radius: 8px;
    border: 1px solid rgba(242, 242, 242, 1);
    box-shadow: 0px 0px 15px rgba(242, 242, 242, 1);
    box-sizing: border-box;

    .tab-scroll {
      overflow-y: scroll;
      width: 100%;
      height: calc(100% - 60px);

      &::-webkit-scrollbar {
        width: 0;
      }
    }

    .tab-position {
      position: absolute;
      right: 10px;
      top: 10px;
    }

    .tab-line {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: 15px 0;

      .tab-key {
        min-width: 100px;
        white-space: nowrap;
        color: #999;
        font-size: 16px;
      }

      .tab-key1 {
        margin-right: 20px;
        white-space: nowrap;
        color: #999;
        font-size: 16px;
      }

      .tab-key2 {
        margin-left: 40px;
      }

      .tab-key3 {
        width: 140px;
      }

      .tab-key4 {
        width: auto;
        margin-right: 10px;
        white-space: nowrap;
        color: #999;
        font-size: 16px;
      }

      .el-radio-group,
      .el-radio {
        display: flex;
        flex-direction: row;
        align-items: center;
      }

      /deep/.el-radio__label {
        font-size: 16px;
      }

      /deep/.el-textarea__inner {
        width: 300px;
        margin-left: 10px;
        resize: none;
      }

      .el-input {
        width: 200px;
        margin-right: 20px;
      }
    }

    .tab-line1 {
      padding-left: 50px;
      box-sizing: border-box;
    }
  }

}
</style>
