<template>
  <div class="tab-box">
    <div class="tab-box-left">
      <div class="tab-search">
        <div class="item-search">
          <span class="span">记录日期</span>
          <el-date-picker v-model="value1" type="date" placeholder="选择日期">
          </el-date-picker>
        </div>
        <div class="item-search">
          <span class="span">记录人</span>
          <el-input v-model="input" placeholder="请输入姓名"></el-input>
        </div>
      </div>
      <div class="tab-table">
        <el-table :data="tableData" stripe border height="100%">
          <el-table-column type="selection" width="55">
          </el-table-column>
          <el-table-column prop="name" label="日期">
          </el-table-column>
          <el-table-column prop="address" label="记录人">
          </el-table-column>
          <el-table-column label="操作">
            <template>
              <el-button type="danger" size="small">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="tab-add">
        <el-button type="primary" @click="showAdd = true">新增</el-button>
      </div>
    </div>
    <div class="tab-scroll">
      <el-checkbox-group v-model="checkList">
        <el-checkbox label="1">最近1年曾有不明原因跌倒经历（1分）</el-checkbox>
        <el-checkbox label="2">意识障碍（1分）</el-checkbox>
        <el-checkbox label="3">视力障碍（单盲、双盲、弱势、白内障、青光眼、眼底病、复视）（1分）</el-checkbox>
        <el-checkbox label="1">活动障碍、肢体偏瘫（3分）</el-checkbox>
        <el-checkbox label="2">年龄(≧65岁)（1分）</el-checkbox>
        <el-checkbox label="3">体能虚弱（生活能部分自理，白天过半时间要卧床或坐椅）（3分）</el-checkbox>
        <el-checkbox label="1">头晕、眩晕、体位性低血压</el-checkbox>
        <el-checkbox label="2">服用影响意识和活动的药物(散瞳剂、镇静安眠剂、降压利尿剂、镇挛抗癫剂、麻醉止痛剂)（1分）</el-checkbox>
        <el-checkbox label="3">住院中无家人和其他人陪伴（1分）</el-checkbox>
      </el-checkbox-group>
      <div class="tab-line">
        <div class="tab-key4">
          <span>总分</span>
        </div>
        <el-input v-model="input" placeholder="请输入"></el-input>
      </div>
    </div>
    <Add v-if="showAdd"></Add>
  </div>
</template>

<script>
import Add from './add.vue'
export default {
  components: {
    Add
  },
  data () {
    return {
      input: '',
      value: '',
      options: [],
      radio: '',
      value1: '',
      textarea: '',
      tableData: [
        {},
        {},
        {}
      ],
      checkList: [],
      showAdd: false
    }
  }
}
</script>

<style lang="less" scoped>
.tab-box {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  box-sizing: border-box;

  .tab-box-left {
    width: 380px;
    height: 100%;
    padding-right: 20px;
    box-sizing: border-box;

    .tab-search {
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-wrap: wrap;
      width: 100%;
      height: 120px;

      .item-search {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        height: 50px;
        margin: 0 10px;
      }

      .span {
        margin-right: 10px;
        white-space: nowrap;
        font-size: 16px;
      }

      .el-input {
        width: 200px;
      }
    }

    .tab-table {
      width: 100%;
      height: calc(100% - 170px);

      /deep/.el-table__cell {
        text-align: center;
      }
    }
  }

  .tab-scroll {
    overflow-y: scroll;
    width: calc(100% - 380px);
    height: 100%;

    &::-webkit-scrollbar {
      width: 0;
    }

    .tab-line {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: 15px 0;

      .tab-key {
        min-width: 100px;
        white-space: nowrap;
        color: #999;
        font-size: 16px;
      }

      .tab-key1 {
        margin-right: 20px;
        white-space: nowrap;
        color: #999;
        font-size: 16px;
      }

      .tab-key2 {
        margin-left: 40px;
      }

      .tab-key3 {
        width: 140px;
      }

      .tab-key4 {
        width: auto;
        margin-right: 10px;
        white-space: nowrap;
        color: #999;
        font-size: 16px;
      }

      .el-radio-group,
      .el-radio {
        display: flex;
        flex-direction: row;
        align-items: center;
      }

      /deep/.el-radio__label {
        font-size: 16px;
      }

      /deep/.el-textarea__inner {
        width: 300px;
        margin-left: 10px;
        resize: none;
      }

      .el-input {
        width: 100px;
        margin-right: 20px;
      }
    }

    .tab-line1 {
      padding-left: 50px;
      box-sizing: border-box;
    }

    .el-checkbox-group {
      display: flex;
      flex-direction: column;
    }

    .el-checkbox {
      margin: 10px 0;
    }

    /deep/.el-checkbox__label {
      font-size: 16px;
    }
  }

  .tab-position {
    position: absolute;
    right: 10px;
    top: 10px;
  }
}
</style>
