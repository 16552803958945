<template>
  <div class="run">
    <div class="run-title">
      <span>交班报告</span>
    </div>
    <div class="run-search">
      <div class="search-item">
        <span class="search-width">日期</span>
        <el-date-picker v-model="value" type="date" placeholder="选择日期">
        </el-date-picker>
      </div>
      <div class="search-item">
        <el-select v-model="value1" placeholder="请选择">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="search-item">
        <el-button type="primary">预览</el-button>
        <el-button type="primary">打印</el-button>
      </div>
    </div>
    <div class="run-card">
      <el-descriptions title="病区统计" :column="5">
        <el-descriptions-item label="原有">0人</el-descriptions-item>
        <el-descriptions-item label="现有" :span="4">30人</el-descriptions-item>
        <el-descriptions-item label="特级护理">30人</el-descriptions-item>
        <el-descriptions-item label="一级护理">30人</el-descriptions-item>
        <el-descriptions-item label="入院">30人</el-descriptions-item>
        <el-descriptions-item label="出院">30人</el-descriptions-item>
        <el-descriptions-item label="死亡">30人</el-descriptions-item>
        <el-descriptions-item label="手术">30人</el-descriptions-item>
        <el-descriptions-item label="明日手术">30人</el-descriptions-item>
        <el-descriptions-item label="病危">30人</el-descriptions-item>
        <el-descriptions-item label="病重">30人</el-descriptions-item>
        <el-descriptions-item label="分娩">30人</el-descriptions-item>
      </el-descriptions>
    </div>
    <div class="run-add">
      <el-button type="primary" @click="openAdd">新增</el-button>
    </div>
    <div class="run-table">
      <div class="run-table-left">
        <div class="head">
          <span>项目</span>
        </div>
        <div class="scroll">
          <div class="scroll-item" :class="{ 'scroll-active': index === tabIndex }" v-for="(item, index) in list" :key="index" @click="tabClick(index)">
            <span>{{ item }}</span>
          </div>
        </div>
      </div>
      <div class="run-table-right">
        <el-table ref="multipleTable" border :data="tableData" height="100%" stripe>
          <el-table-column label="床位"></el-table-column>
            <el-table-column prop="name" label="姓名">
            </el-table-column>
            <el-table-column label="性别">
            </el-table-column>
            <el-table-column label="年龄">
            </el-table-column>
            <el-table-column label="诊断">
            </el-table-column>
            <el-table-column label="描述">
            </el-table-column>
            <el-table-column label="备注">
            </el-table-column>
            <el-table-column label="操作">
          <!-- slot-scope="scope" -->
          <template>
            <el-button size="mini" type="danger" @click="del">删除</el-button>
          </template>
        </el-table-column>
          </el-table>
      </div>
    </div>
    <div class="run-footer">
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="1"
        :page-sizes="[100, 200, 300, 400]" :page-size="100" layout="total, sizes, prev, pager, next, jumper"
        :total="400">
      </el-pagination>
    </div>
    <Add v-if="showAdd"></Add>
  </div>
</template>

<script>
import Add from './add.vue'
export default {
  components: {
    Add
  },
  data () {
    return {
      value: '',
      value1: '',
      options: [
        { label: '全部', value: '1' },
        { label: '白班', value: '2' },
        { label: '晚班', value: '3' }
      ],
      list: Object.freeze(['特级护理', '一级护理', '入院', '出院', '死亡', '手术', '病危', '病重', '分娩', '转入', '转出', '体温异常']),
      tabIndex: -1,
      tableData: [
        {},
        {},
        {}
      ],
      showAdd: false
    }
  },
  methods: {
    handleSizeChange (val) {
      console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      console.log(`当前页: ${val}`)
    },
    tabClick (index) {
      this.tabIndex = index
    },
    openAdd () {
      this.showAdd = true
    },
    del () {
      this.$message({
        message: '删除成功',
        type: 'success'
      })
    }
  }
}
</script>

<style lang="less" scoped>
.run {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 10px;
  background-color: #fff;
  box-sizing: border-box;

  .run-title {
    width: 100%;
    height: 60px;
    line-height: 60px;
    border-bottom: 1px solid rgba(0, 0, 0, .1);
    box-sizing: border-box;
    font-size: 18px;
  }

  .run-search {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    height: 100px;

    .search-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 25%;

      .el-button {
        margin-left: 40px;
      }
    }

    .search-width {
      width: 100px;
      text-align: center;
      color: #999999;
      font-size: 13px;
    }
  }

  .run-card {
    width: 100%;
    height: 160px;

    .el-descriptions {
      padding: 5px 20px;
      border-radius: 8px;
      border: 1px solid rgba(242, 242, 242, 1);
      box-sizing: border-box;
      box-shadow: 0px 0px 15px rgba(242, 242, 242, 1);
    }

    /deep/.el-descriptions-item__label, /deep/.el-descriptions-item__content {
      font-size: 16px;
    }
  }

  .run-add {
    display: flex;
    flex-direction: row-reverse;
    width: 100%;
    height: 40px;
    margin-top: 10px;
    padding-right: 10px;
    box-sizing: border-box;
  }

  .run-table {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: calc(100% - 450px);
    margin-top: 10px;

    .run-table-left {
      width: 200px;
      height: 100%;

      .head {
        width: 100%;
        height: 40px;
        line-height: 40px;
        text-align: center;
        font-size: 16px;
      }

      .scroll {
        overflow-y: scroll;
        width: 100%;
        height: calc(100% - 40px);

        &::-webkit-scrollbar {
          width: 0;
        }

        .scroll-item {
          width: 100%;
          height: 30px;
          line-height: 30px;
          text-align: center;
          border-top: 1px solid rgba(0, 0, 0, .1);
          box-sizing: border-box;
          font-size: 14px;
          cursor: pointer;
        }

        .scroll-item:last-child {
          border-bottom: 1px solid rgba(0, 0, 0, .1);
        }

        .scroll-active {
          background-color: rgba(242, 247, 251, 1);
        }
      }
    }

    .run-table-right {
      width: calc(100% - 200px);
      height: 100%;
    }

    /deep/.el-table__cell {
      text-align: center;
    }

    .yellow {
      background-color: rgba(255, 153, 0, 1);
    }

    .green {
      background-color: rgba(0, 153, 0, 1);
    }

    .red {
      background-color: rgba(255, 0, 0, 1);
    }

    .blue {
      background-color: rgba(37, 97, 239, 1);
    }
  }

  .run-footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 70px;
  }
}
</style>
