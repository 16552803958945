<template>
  <div class="add">
    <div class="add-scroll">
      <el-checkbox-group v-model="checkList">
        <el-checkbox label="1">最近1年曾有不明原因跌倒经历（1分）</el-checkbox>
        <el-checkbox label="2">意识障碍（1分）</el-checkbox>
        <el-checkbox label="3">视力障碍（单盲、双盲、弱势、白内障、青光眼、眼底病、复视）（1分）</el-checkbox>
        <el-checkbox label="1">活动障碍、肢体偏瘫（3分）</el-checkbox>
        <el-checkbox label="2">年龄(≧65岁)（1分）</el-checkbox>
        <el-checkbox label="3">体能虚弱（生活能部分自理，白天过半时间要卧床或坐椅）（3分）</el-checkbox>
        <el-checkbox label="1">头晕、眩晕、体位性低血压</el-checkbox>
        <el-checkbox label="2">服用影响意识和活动的药物(散瞳剂、镇静安眠剂、降压利尿剂、镇挛抗癫剂、麻醉止痛剂)（1分）</el-checkbox>
        <el-checkbox label="3">住院中无家人和其他人陪伴（1分）</el-checkbox>
      </el-checkbox-group>
      <div class="add-line">
        <div class="add-key4">
          <span>总分</span>
        </div>
        <el-input v-model="input" placeholder="请输入"></el-input>
      </div>
    </div>
    <div class="add-button">
      <el-button type="primary" @click="close">保存</el-button>
      <el-button @click="close">取消</el-button>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      input: '',
      value: '',
      options: [],
      radio: '',
      value1: '',
      textarea: '',
      checkList: []
    }
  },
  methods: {
    close () {
      this.$parent.showAdd = false
    }
  }
}
</script>

<style lang="less" scoped>
.add {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 88;
  padding: 0 20px;
  background-color: #fff;
  box-sizing: border-box;

  .add-scroll {
    overflow-y: scroll;
    width: 100%;
    height: calc(100% - 60px);

    &::-webkit-scrollbar {
      width: 0;
    }

    .el-checkbox-group {
      display: flex;
      flex-direction: column;
    }

    .el-checkbox {
      margin: 10px 0;
    }

    /deep/.el-checkbox__label {
      font-size: 16px;
    }
  }

  .add-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 60px;
  }

  .add-line {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 15px 0;

    .add-key {
      min-width: 100px;
      white-space: nowrap;
      color: #999;
      font-size: 16px;
    }

    .add-key1 {
      margin-right: 20px;
      white-space: nowrap;
      color: #999;
      font-size: 16px;
    }

    .add-key2 {
      margin-left: 40px;
    }

    .add-key3 {
      width: 140px;
    }

    .add-key4 {
      width: auto;
      margin-right: 10px;
      white-space: nowrap;
      color: #999;
      font-size: 16px;
    }

    .el-radio-group,
    .el-radio {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    /deep/.el-radio__label {
      font-size: 16px;
    }

    /deep/.el-textarea__inner {
      width: 300px;
      margin-left: 10px;
      resize: none;
    }

    .el-input {
      width: 100px;
      margin-right: 20px;
    }
  }

  .add-line1 {
    padding-left: 50px;
    box-sizing: border-box;
  }
}
</style>
