<template>
  <div class="pop">
    <div class="pop-head">
      <div class="pop-item pop-active" @click="tabClick(0)">
        <span>标签打印</span>
      </div>
      <div class="pop-item" @click="tabClick(1)">
        <span>体温单录入</span>
      </div>
    </div>
    <div class="pop-box">
      <div class="pop-left">
        <div class="pop-title">
          <span>选择患者</span>
        </div>
        <div class="pop-search">
          <div class="item-search">
            <span class="span">床位</span>
            <el-input v-model="input" placeholder="请输入床位"></el-input>
          </div>
          <div class="item-search">
            <span class="span">姓名</span>
            <el-input v-model="input" placeholder="请输入姓名"></el-input>
          </div>
        </div>
        <div class="pop-table">
          <el-table :data="tableData" stripe border height="100%">
            <el-table-column type="selection" width="55">
            </el-table-column>
            <el-table-column prop="name" label="床位">
            </el-table-column>
            <el-table-column prop="address" label="姓名">
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="pop-right">
        <div class="pop-title">
          <span>打印设置</span>
        </div>
        <div class="pop-print">
          <span @click="openPrint">批量打印</span>
          <span class="span el-icon-printer" @click="openPrint"></span>
        </div>
        <div class="pop-table">
          <el-table :data="tableData" stripe border height="100%">
            <el-table-column prop="name" label="床位">
            </el-table-column>
            <el-table-column prop="address" label="姓名">
            </el-table-column>
            <el-table-column prop="address" label="性别">
            </el-table-column>
            <el-table-column prop="address" label="年龄">
            </el-table-column>
            <el-table-column prop="address" label="住院号">
            </el-table-column>
            <el-table-column label="操作">
              <template>
                <el-button type="primary" size="small" @click="openPrint">打印</el-button>
                <el-button type="danger" size="small">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <Enter v-if="showEnter"></Enter>
    <Print v-if="showPrint"></Print>
  </div>
</template>

<script>
import Enter from './enter.vue'
import Print from './print.vue'
export default {
  components: {
    Enter,
    Print
  },
  data () {
    return {
      tabIndex: 0,
      input: '',
      tableData: [
        {},
        {},
        {}
      ],
      showEnter: false,
      showPrint: false
    }
  },
  methods: {
    tabClick (index) {
      if (index === 1) {
        this.showEnter = true
        return
      }
      this.tabIndex = index
    },
    openPrint () {
      this.showPrint = true
    }
  }
}
</script>

<style lang="less" scoped>
.pop {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 10px;
  background-color: #fff;
  box-sizing: border-box;

  .pop-head {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 48px;
    padding-right: 40px;
    box-sizing: border-box;

    .pop-item {
      height: 48px;
      line-height: 48px;
      padding: 0 40px;
      background-color: rgba(242, 247, 251, 0.5);
      color: #1877F2;
      font-size: 16px;
      cursor: pointer;
    }

    .pop-active {
      color: #fff;
      background-color: rgba(24, 119, 242, 1);
    }
  }

  .pop-box {
    display: flex;
    width: 100%;
    height: calc(100% - 58px);
    margin-top: 10px;

    .pop-left {
      width: 380px;
      height: 100%;
      border-radius: 8px;
      border: 1px solid rgba(242, 242, 242, 1);
      box-shadow: 0px 0px 15px rgba(242, 242, 242, 1);
      box-sizing: border-box;

      .pop-search {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        width: 100%;
        height: 60px;

        .item-search {
          display: flex;
          flex-direction: row;
          align-items: center;
          width: 45%;
          height: 50px;
        }

        .span {
          margin-right: 10px;
          white-space: nowrap;
          font-size: 16px;
        }
      }
    }

    .pop-right {
      width: calc(100% - 400px);
      height: 100%;
      margin-left: 20px;
      border-radius: 8px;
      border: 1px solid rgba(242, 242, 242, 1);
      box-shadow: 0px 0px 15px rgba(242, 242, 242, 1);
      box-sizing: border-box;

      .pop-print {
        display: flex;
        flex-direction: row-reverse;
        width: 100%;
        height: 60px;
        padding-right: 80px;
        box-sizing: border-box;
        font-size: 16px;

        span {
          cursor: pointer;
        }

        .span {
          margin-right: 20px;
          font-size: 24px;
        }
      }
    }

    .pop-title {
      width: 100%;
      height: 50px;
      line-height: 50px;
      text-align: center;
      font-size: 16px;
    }

    .pop-table {
      width: 100%;
      height: calc(100% - 110px);

      /deep/.el-table__cell {
        text-align: center;
      }
    }
  }
}
</style>
