<template>
  <div class="add">
    <div class="add-scroll">
      <div class="add-line">
        <div class="add-key">
          <span>感知</span>
        </div>
        <el-radio-group v-model="radio">
          <el-radio label="1">完全受限（1分）</el-radio>
          <el-radio label="2">非常受限（2分）</el-radio>
          <el-radio label="3">轻微受限（3分）</el-radio>
          <el-radio label="4">无缺损（4分）</el-radio>
        </el-radio-group>
      </div>
      <div class="add-line">
        <div class="add-key">
          <span>潮湿</span>
        </div>
        <el-radio-group v-model="radio">
          <el-radio label="1">持续潮湿（1分）</el-radio>
          <el-radio label="2">潮湿（2分）</el-radio>
          <el-radio label="3">有时潮湿（3分）</el-radio>
          <el-radio label="4">很少潮湿（4分）</el-radio>
        </el-radio-group>
      </div>
      <div class="add-line">
        <div class="add-key">
          <span>活动能力</span>
        </div>
        <el-radio-group v-model="radio">
          <el-radio label="1">卧床不起（1分）</el-radio>
          <el-radio label="2">局限于椅（2分）</el-radio>
          <el-radio label="3">偶尔行走（3分）</el-radio>
          <el-radio label="4">经常行走（4分）</el-radio>
        </el-radio-group>
      </div>
      <div class="add-line">
        <div class="add-key">
          <span>移动能力</span>
        </div>
        <el-radio-group v-model="radio">
          <el-radio label="1">完全无法移动（1分）</el-radio>
          <el-radio label="2">严重受限（2分）</el-radio>
          <el-radio label="3">轻度受限（3分）</el-radio>
          <el-radio label="4">不受限（4分）</el-radio>
        </el-radio-group>
      </div>
      <div class="add-line">
        <div class="add-key">
          <span>皮肤</span>
        </div>
        <el-radio-group v-model="radio">
          <el-radio label="1">非常差（1分）</el-radio>
          <el-radio label="2">可能不足（2分）</el-radio>
          <el-radio label="3">足够（3分）</el-radio>
          <el-radio label="4">非常好（4分）</el-radio>
        </el-radio-group>
      </div>
      <div class="add-line">
        <div class="add-key add-key3">
          <span>摩擦力和剪切力</span>
        </div>
        <el-radio-group v-model="radio">
          <el-radio label="1">已成问题（1分）</el-radio>
          <el-radio label="2">有潜在问题（2分）</el-radio>
          <el-radio label="3">无明显问题（3分）</el-radio>
        </el-radio-group>
      </div>
      <div class="add-line">
        <div class="add-key4">
          <span>总分</span>
        </div>
        <el-input v-model="input" placeholder="请输入"></el-input>
      </div>
    </div>
    <div class="add-button">
      <el-button type="primary" @click="close">保存</el-button>
      <el-button @click="close">取消</el-button>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      input: '',
      value: '',
      options: [],
      radio: '',
      value1: '',
      textarea: ''
    }
  },
  methods: {
    close () {
      this.$parent.showAdd = false
    }
  }
}
</script>

<style lang="less" scoped>
.add {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 88;
  padding: 0 20px;
  background-color: #fff;
  box-sizing: border-box;

  .add-scroll {
    overflow-y: scroll;
    width: 100%;
    height: calc(100% - 60px);

    &::-webkit-scrollbar {
      width: 0;
    }
  }

  .add-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 60px;
  }

  .add-line {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 15px 0;

    .add-key {
      min-width: 100px;
      white-space: nowrap;
      color: #999;
      font-size: 16px;
    }

    .add-key1 {
      margin-right: 20px;
      white-space: nowrap;
      color: #999;
      font-size: 16px;
    }

    .add-key2 {
      margin-left: 40px;
    }

    .add-key3 {
      width: 140px;
    }

    .add-key4 {
      width: auto;
      margin-right: 10px;
      white-space: nowrap;
      color: #999;
      font-size: 16px;
    }

    .el-radio-group,
    .el-radio {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    /deep/.el-radio__label {
      font-size: 16px;
    }

    /deep/.el-textarea__inner {
      width: 300px;
      margin-left: 10px;
      resize: none;
    }

    .el-input {
      width: 100px;
      margin-right: 20px;
    }
  }

  .add-line1 {
    padding-left: 50px;
    box-sizing: border-box;
  }
}
</style>
