<template>
  <div class="run">
    <div class="run-title">
      <span>文书模板维护</span>
    </div>
    <div class="run-search">
      <div class="search-item">
        <el-button type="primary" @click="openAdd">刷新</el-button>
      </div>
      <div class="search-item">
        <el-button type="primary" @click="openAdd">保存</el-button>
      </div>
    </div>
    <div class="run-box">
      <div class="run-left">
        <div class="box-title">
          <span>未设置</span>
        </div>
        <div class="box-content">
          <el-table :data="tableData" border stripe height="100%">
            <el-table-column type="selection" width="55">
            </el-table-column>
            <el-table-column prop="name" label="文书名称">
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="run-center">
        <el-button type="info">
          <span class="el-icon-d-arrow-right"></span>
        </el-button>
        <el-button type="info">
          <span class="el-icon-d-arrow-left"></span>
        </el-button>
      </div>
      <div class="run-right">
        <div class="box-title">
          <span>菜单</span>
        </div>
        <div class="box-content">
          <div class="tree">
            <span class="el-icon-remove-outline icon" @click="show = !show"></span>
            <span class="span">评估记录单</span>
            <span class="el-icon-plus icon" @click="add"></span>
          </div>
          <div class="tree-children" v-for="(item, index) in treeData" :key="item.id" v-show="show">
            <div class="tree-left">
              <div class="tree-left-item"></div>
              <div class="tree-left-item"></div>
            </div>
            <div class="tree-text">
              <el-input v-model="item.label" :ref="'ref' + index" :disabled="!item.select" placeholder="" @blur="blur(index)"></el-input>
            </div>
            <div class="tree-icon" @click="update(item, index)">
              <span class="el-icon-edit"></span>
            </div>
            <div class="tree-icon" @click="del(item.id)">
              <span class="el-icon-delete"></span>
            </div>
            <div class="tree-icon" v-show="index !== treeData.length - 1" @click="down(item.id, index)">
              <span class="el-icon-bottom"></span>
            </div>
            <div class="tree-icon" v-show="index !== 0" @click="up(item.id, index)">
              <span class="el-icon-top"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data () {
    return {
      value: '',
      input: '',
      tableData: [
        { name: '评估记录单' },
        { name: '护理文书' },
        { name: '护理评分' }
      ],
      showAdd: false,
      showUpdate: false,
      show: true,
      treeData: [
        { id: 1, select: false, label: '入院评估' },
        { id: 2, select: false, label: '每日护理评估单' },
        { id: 3, select: false, label: '健康教育记录单' },
        { id: 4, select: false, label: '每日评估' }
      ]
    }
  },
  methods: {
    handleSizeChange (val) {
      console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      console.log(`当前页: ${val}`)
    },
    openAdd () {
      this.showAdd = true
    },
    openUpdate () {
      this.showUpdate = true
    },
    add () {
      const id = this.treeData[this.treeData.length - 1].id
      const newId = id + 1
      const obj = { id: newId, select: false, label: '' }
      this.treeData.push(obj)
    },
    update (item, index) {
      item.select = true
      this.$nextTick(() => {
        this.$refs['ref' + index][0].focus()
      })
    },
    del (id) {
      this.treeData = this.treeData.filter(item => item.id !== id)
    },
    down (id, index) {
      console.log(id, index)
      const treeData = JSON.parse(JSON.stringify(this.treeData))
      const arr = treeData[index + 1]
      treeData[index + 1] = treeData[index]
      treeData[index] = arr
      this.treeData = treeData
      console.log(treeData)
    },
    up (id, index) {
      console.log(id, index)
      const treeData = JSON.parse(JSON.stringify(this.treeData))
      const arr = treeData[index - 1]
      treeData[index - 1] = treeData[index]
      treeData[index] = arr
      this.treeData = treeData
      console.log(treeData)
    },
    blur (index) {
      if (!this.treeData[index].select) return
      this.treeData[index].select = false
    }
  }
}
</script>

<style lang="less" scoped>
.run {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 10px;
  background-color: #fff;
  box-sizing: border-box;

  .run-search {
    display: flex;
    flex-direction: row-reverse;
    width: 100%;
    height: 80px;

    .search-item {
      display: flex;
      flex-direction: row;
      align-items: center;

      .el-button {
        margin: 0 20px;
      }
    }
  }

  .run-title {
    width: 100%;
    height: 48px;
    line-height: 48px;
    padding-right: 40px;
    border-bottom: 1px solid rgba(0, 0, 0, .1);
    box-sizing: border-box;
    font-size: 20px;
  }

  .run-box {
    display: flex;
    width: 100%;
    height: calc(100% - 138px);
    margin-top: 10px;

    .run-left {
      width: 300px;
      height: 100%;
      padding: 10px;
      border: 1px solid rgba(242, 242, 242, 1);
      box-sizing: border-box;
      border-radius: 8px;
      box-shadow: 0px 0px 15px rgba(242, 242, 242, 1);
    }

    .run-center {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100px;
      height: 100%;
      padding-top: 150px;
      box-sizing: border-box;

      .el-button {
        margin: 0;
        margin-top: 20px;
      }
    }

    .run-right {
      width: calc(100% - 400px);
      height: 100%;
      padding: 20px;
      border: 1px solid rgba(242, 242, 242, 1);
      box-sizing: border-box;
      border-radius: 8px;
      box-shadow: 0px 0px 15px rgba(242, 242, 242, 1);

      .tree {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 20px;

        .span {
          margin: 0 20px;
          font-size: 16px;
        }

        .icon {
          color: #d5d5d5;
          cursor: pointer;
        }
      }

      .tree-children {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: calc(100% - 70px);
        height: 60px;
        margin-left: 70px;

        .tree-left {
          width: 45px;
          height: 60px;

          .tree-left-item {
            width: 100%;
            height: 30px;
            border-left: 1px solid #d5d5d5;
            box-sizing: border-box;
          }

          .tree-left-item:last-child {
            border-top: 1px dashed #d5d5d5;
          }
        }

        .tree-text {
          width: 180px;

          /deep/.el-input__inner {
            border: none;
            font-size: 16px;
          }

          /deep/.el-input.is-disabled .el-input__inner {
            background-color: #fff;
            color: #000;
            font-size: 16px;
            cursor: auto;
          }
        }

        .tree-icon {
          margin: 0 10px;
          cursor: pointer;
          color: #d5d5d5;
          font-size: 20px;
        }
      }

      .tree-children:last-child .tree-left .tree-left-item:last-child {
        border-left: none;
      }
    }

    .box-title {
      width: 100%;
      height: 50px;
      line-height: 50px;
      font-size: 18px;
    }

    .box-content {
      overflow-y: scroll;
      width: 100%;
      height: calc(100% - 50px);

      &::-webkit-scrollbar {
        width: 0;
      }

      /deep/.el-table__cell {
        text-align: center;
      }
    }
  }
}
</style>
