<template>
  <div class="tab-box">
    <div class="tab-box-left">
      <div class="tab-search">
        <div class="item-search">
          <span class="span">记录日期</span>
          <el-date-picker v-model="value1" type="date" placeholder="选择日期">
          </el-date-picker>
        </div>
        <div class="item-search">
          <span class="span">记录人</span>
          <el-input v-model="input" placeholder="请输入姓名"></el-input>
        </div>
      </div>
      <div class="tab-table">
        <el-table :data="tableData" stripe border height="100%">
          <el-table-column type="selection" width="55">
          </el-table-column>
          <el-table-column prop="name" label="日期">
          </el-table-column>
          <el-table-column prop="address" label="记录人">
          </el-table-column>
          <el-table-column label="操作">
            <template>
              <el-button type="danger" size="small">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="tab-add">
        <el-button type="primary" @click="showAdd = true">新增</el-button>
      </div>
    </div>
    <div class="tab-scroll">
      <div class="tab-line">
        <div class="tab-key">
          <span>感知</span>
        </div>
        <el-radio-group v-model="radio">
          <el-radio label="1">完全受限（1分）</el-radio>
          <el-radio label="2">非常受限（2分）</el-radio>
          <el-radio label="3">轻微受限（3分）</el-radio>
          <el-radio label="4">无缺损（4分）</el-radio>
        </el-radio-group>
      </div>
      <div class="tab-line">
        <div class="tab-key">
          <span>潮湿</span>
        </div>
        <el-radio-group v-model="radio">
          <el-radio label="1">持续潮湿（1分）</el-radio>
          <el-radio label="2">潮湿（2分）</el-radio>
          <el-radio label="3">有时潮湿（3分）</el-radio>
          <el-radio label="4">很少潮湿（4分）</el-radio>
        </el-radio-group>
      </div>
      <div class="tab-line">
        <div class="tab-key">
          <span>活动能力</span>
        </div>
        <el-radio-group v-model="radio">
          <el-radio label="1">卧床不起（1分）</el-radio>
          <el-radio label="2">局限于椅（2分）</el-radio>
          <el-radio label="3">偶尔行走（3分）</el-radio>
          <el-radio label="4">经常行走（4分）</el-radio>
        </el-radio-group>
      </div>
      <div class="tab-line">
        <div class="tab-key">
          <span>移动能力</span>
        </div>
        <el-radio-group v-model="radio">
          <el-radio label="1">完全无法移动（1分）</el-radio>
          <el-radio label="2">严重受限（2分）</el-radio>
          <el-radio label="3">轻度受限（3分）</el-radio>
          <el-radio label="4">不受限（4分）</el-radio>
        </el-radio-group>
      </div>
      <div class="tab-line">
        <div class="tab-key">
          <span>皮肤</span>
        </div>
        <el-radio-group v-model="radio">
          <el-radio label="1">非常差（1分）</el-radio>
          <el-radio label="2">可能不足（2分）</el-radio>
          <el-radio label="3">足够（3分）</el-radio>
          <el-radio label="4">非常好（4分）</el-radio>
        </el-radio-group>
      </div>
      <div class="tab-line">
        <div class="tab-key tab-key3">
          <span>摩擦力和剪切力</span>
        </div>
        <el-radio-group v-model="radio">
          <el-radio label="1">已成问题（1分）</el-radio>
          <el-radio label="2">有潜在问题（2分）</el-radio>
          <el-radio label="3">无明显问题（3分）</el-radio>
        </el-radio-group>
      </div>
      <div class="tab-line">
        <div class="tab-key4">
          <span>总分</span>
        </div>
        <el-input v-model="input" placeholder="请输入"></el-input>
      </div>
    </div>
    <Add v-if="showAdd"></Add>
  </div>
</template>

<script>
import Add from './add.vue'
export default {
  components: {
    Add
  },
  data () {
    return {
      input: '',
      value: '',
      options: [],
      radio: '',
      value1: '',
      textarea: '',
      tableData: [
        {},
        {},
        {}
      ],
      showAdd: false
    }
  }
}
</script>

<style lang="less" scoped>
.tab-box {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  box-sizing: border-box;

  .tab-box-left {
    width: 380px;
    height: 100%;
    padding-right: 20px;
    box-sizing: border-box;

    .tab-search {
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-wrap: wrap;
      width: 100%;
      height: 120px;

      .item-search {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        height: 50px;
        margin: 0 10px;
      }

      .span {
        margin-right: 10px;
        white-space: nowrap;
        font-size: 16px;
      }

      .el-input {
        width: 200px;
      }
    }

    .tab-table {
      width: 100%;
      height: calc(100% - 170px);

      /deep/.el-table__cell {
        text-align: center;
      }
    }
  }

  .tab-scroll {
    overflow-y: scroll;
    width: calc(100% - 380px);
    height: 100%;

    &::-webkit-scrollbar {
      width: 0;
    }

    .tab-line {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: 15px 0;

      .tab-key {
        min-width: 100px;
        white-space: nowrap;
        color: #999;
        font-size: 16px;
      }

      .tab-key1 {
        margin-right: 20px;
        white-space: nowrap;
        color: #999;
        font-size: 16px;
      }

      .tab-key2 {
        margin-left: 40px;
      }

      .tab-key3 {
        width: 140px;
      }

      .tab-key4 {
        width: auto;
        margin-right: 10px;
        white-space: nowrap;
        color: #999;
        font-size: 16px;
      }

      .el-radio-group,
      .el-radio {
        display: flex;
        flex-direction: row;
        align-items: center;
      }

      /deep/.el-radio__label {
        font-size: 16px;
      }

      /deep/.el-textarea__inner {
        width: 300px;
        margin-left: 10px;
        resize: none;
      }

      .el-input {
        width: 100px;
        margin-right: 20px;
      }
    }

    .tab-line1 {
      padding-left: 50px;
      box-sizing: border-box;
    }
  }

  .tab-position {
    position: absolute;
    right: 10px;
    top: 10px;
  }
}
</style>
