<template>
  <div class="tab-box">
    <div class="tab-box-left">
      <div class="tab-search">
        <div class="item-search">
          <span class="span">记录日期</span>
          <el-date-picker v-model="value1" type="date" placeholder="选择日期">
          </el-date-picker>
        </div>
        <div class="item-search">
          <span class="span">记录人</span>
          <el-input v-model="input" placeholder="请输入姓名"></el-input>
        </div>
      </div>
      <div class="tab-table">
        <el-table :data="tableData" stripe border height="100%">
          <el-table-column type="selection" width="55">
          </el-table-column>
          <el-table-column prop="name" label="日期">
          </el-table-column>
          <el-table-column prop="address" label="记录人">
          </el-table-column>
          <el-table-column label="操作">
            <template>
              <el-button type="danger" size="small">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="tab-add">
        <el-button type="primary" @click="showAdd = true">新增</el-button>
      </div>
    </div>
    <div class="tab-scroll">
      <div class="tab-line">
        <div class="tab-key">
          <span>眼睁反应（E）</span>
        </div>
        <el-radio-group v-model="radio">
          <el-radio label="1">自动睁眼（4分）</el-radio>
          <el-radio label="2">呼唤睁眼（3分）</el-radio>
          <el-radio label="3">刺痛睁眼（2分）</el-radio>
          <el-radio label="4">无反应（1分）</el-radio>
        </el-radio-group>
      </div>
      <div class="tab-line">
        <div class="tab-key">
          <span>语言反应（V）</span>
        </div>
        <el-radio-group v-model="radio">
          <el-radio label="1">回答正确（4分）</el-radio>
          <el-radio label="2">对打混乱（3分）</el-radio>
          <el-radio label="3">答非所问（2分）</el-radio>
          <el-radio label="4">含糊不清的声音（2分）</el-radio>
          <el-radio label="5">无反应（1分）</el-radio>
        </el-radio-group>
      </div>
      <div class="tab-line">
        <div class="tab-key">
          <span>运动反应（M）</span>
        </div>
        <el-radio-group v-model="radio">
          <el-radio label="1">正确执行命令（6分）</el-radio>
          <el-radio label="2">对刺激定位（分）</el-radio>
          <el-radio label="3">逃避刺激（4分）</el-radio>
          <el-radio label="4">刺痛屈曲（去皮层强直）（3分）</el-radio>
          <el-radio label="5">刺痛伸展（去大脑强直）（2分）</el-radio>
          <el-radio label="6">无反应（1分）</el-radio>
        </el-radio-group>
      </div>
      <div class="tab-line">
        <div class="tab-key4">
          <span>总分</span>
        </div>
        <el-input v-model="input" placeholder="请输入"></el-input>
      </div>
    </div>
    <Add v-if="showAdd"></Add>
  </div>
</template>

<script>
import Add from './add.vue'
export default {
  components: {
    Add
  },
  data () {
    return {
      input: '',
      value: '',
      options: [],
      radio: '',
      value1: '',
      textarea: '',
      tableData: [
        {},
        {},
        {}
      ],
      showAdd: false
    }
  }
}
</script>

<style lang="less" scoped>
.tab-box {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  box-sizing: border-box;

  .tab-box-left {
    width: 380px;
    height: 100%;
    padding-right: 20px;
    box-sizing: border-box;

    .tab-search {
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-wrap: wrap;
      width: 100%;
      height: 120px;

      .item-search {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        height: 50px;
        margin: 0 10px;
      }

      .span {
        margin-right: 10px;
        white-space: nowrap;
        font-size: 16px;
      }

      .el-input {
        width: 200px;
      }
    }

    .tab-table {
      width: 100%;
      height: calc(100% - 170px);

      /deep/.el-table__cell {
        text-align: center;
      }
    }
  }

  .tab-scroll {
    overflow-y: scroll;
    width: calc(100% - 380px);
    height: 100%;

    &::-webkit-scrollbar {
      width: 0;
    }

    .tab-line {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: 15px 0;

      .tab-key {
        min-width: 100px;
        white-space: nowrap;
        color: #999;
        font-size: 16px;
      }

      .tab-key1 {
        margin-right: 20px;
        white-space: nowrap;
        color: #999;
        font-size: 16px;
      }

      .tab-key2 {
        margin-left: 40px;
      }

      .tab-key3 {
        width: 140px;
      }

      .tab-key4 {
        width: auto;
        margin-right: 10px;
        white-space: nowrap;
        color: #999;
        font-size: 16px;
      }

      .el-radio-group {
        width: calc(100% - 200px);
        flex-wrap: wrap;
      }

      .el-radio-group,
      .el-radio {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 4px 0;
      }

      /deep/.el-radio__label {
        font-size: 16px;
      }

      /deep/.el-textarea__inner {
        width: 300px;
        margin-left: 10px;
        resize: none;
      }

      .el-input {
        width: 100px;
        margin-right: 20px;
      }
    }

    .tab-line1 {
      padding-left: 50px;
      box-sizing: border-box;
    }
  }

  .tab-position {
    position: absolute;
    right: 10px;
    top: 10px;
  }
}
</style>
