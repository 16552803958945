<template>
  <div class="add">
    <div class="add-scroll">
      <div class="add-line">
        <div class="add-key">
          <span>生命体征</span>
        </div>
        <div class="add-key4">
          <span>体温</span>
        </div>
        <el-input v-model="input" placeholder="请输入"></el-input>
        <div class="add-key4">
          <span>脉搏</span>
        </div>
        <el-input v-model="input" placeholder="请输入"></el-input>
        <div class="add-key4">
          <span>呼吸</span>
        </div>
        <el-input v-model="input" placeholder="请输入"></el-input>
      </div>
      <div class="add-line">
        <div class="add-key">
          <span>出入量</span>
        </div>
        <div class="add-key4">
          <span>输液</span>
        </div>
        <el-input v-model="input" placeholder="请输入"></el-input>
        <div class="add-key4">
          <span>尿量</span>
        </div>
        <el-input v-model="input" placeholder="请输入"></el-input>
      </div>
      <div class="add-line">
        <div class="add-key">
          <span>意识水平</span>
        </div>
        <el-radio-group v-model="radio">
          <el-radio label="1">清醒</el-radio>
          <el-radio label="2">嗜睡</el-radio>
          <el-radio label="3">昏迷</el-radio>
          <el-radio label="4">其他</el-radio>
        </el-radio-group>
        <el-input v-model="input" placeholder="请输入"></el-input>
      </div>
      <div class="add-line">
        <div class="add-key">
          <span>呼吸</span>
        </div>
        <el-radio-group v-model="radio">
          <el-radio label="1">正常</el-radio>
          <el-radio label="2">异常</el-radio>
        </el-radio-group>
      </div>
      <div class="add-line">
        <div class="add-key">
          <span>心律</span>
        </div>
        <el-radio-group v-model="radio">
          <el-radio label="1">齐</el-radio>
          <el-radio label="2">不齐</el-radio>
        </el-radio-group>
      </div>
      <div class="add-line">
        <div class="add-key">
          <span>心率</span>
        </div>
        <el-radio-group v-model="radio">
          <el-radio label="1">正常</el-radio>
          <el-radio label="2">异常</el-radio>
        </el-radio-group>
      </div>
      <div class="add-line">
        <div class="add-key">
          <span>皮肤</span>
        </div>
        <el-radio-group v-model="radio">
          <el-radio label="1">正常</el-radio>
          <el-radio label="2">有压疮危险</el-radio>
          <el-radio label="3">异常</el-radio>
        </el-radio-group>
      </div>
      <div class="add-line">
        <div class="add-key add-key3">
          <span>自理能力</span>
        </div>
        <el-radio-group v-model="radio">
          <el-radio label="1">能</el-radio>
          <el-radio label="2">不能</el-radio>
          <el-radio label="3">部分</el-radio>
        </el-radio-group>
      </div>
      <div class="add-line">
        <div class="add-key add-key3">
          <span>身体状况</span>
        </div>
      </div>
      <div class="add-line add-line1">
        <div class="add-key">
          <span>1.睡眠</span>
        </div>
        <el-radio-group v-model="radio">
          <el-radio label="1">正常</el-radio>
          <el-radio label="2">异常</el-radio>
        </el-radio-group>
      </div>
      <div class="add-line add-line1">
        <div class="add-key">
          <span>2.饮食</span>
        </div>
        <el-radio-group v-model="radio">
          <el-radio label="1">普食</el-radio>
          <el-radio label="2">半流食</el-radio>
          <el-radio label="3">流食</el-radio>
          <el-radio label="4">禁食</el-radio>
          <el-radio label="5">胃肠外营养</el-radio>
          <el-radio label="6">管饲</el-radio>
          <el-radio label="7">其他</el-radio>
        </el-radio-group>
      </div>
      <div class="add-line add-line1">
        <div class="add-key">
          <span>3.排尿</span>
        </div>
        <el-radio-group v-model="radio">
          <el-radio label="1">正常</el-radio>
          <el-radio label="2">异常</el-radio>
          <el-radio label="3">尿频</el-radio>
          <el-radio label="4">排尿困难</el-radio>
          <el-radio label="5">失禁</el-radio>
          <el-radio label="6">其他</el-radio>
        </el-radio-group>
      </div>
      <div class="add-line add-line1">
        <div class="add-key">
          <span>4.排便</span>
        </div>
        <el-radio-group v-model="radio">
          <el-radio label="1">正常</el-radio>
          <el-radio label="2">异常</el-radio>
          <el-radio label="3">便秘</el-radio>
          <el-radio label="4">腹泻</el-radio>
          <el-radio label="5">失禁</el-radio>
          <el-radio label="6">造口</el-radio>
          <el-radio label="7">其他</el-radio>
        </el-radio-group>
      </div>
      <div class="add-line">
        <div class="add-key add-key3">
          <span>管路</span>
        </div>
        <el-radio-group v-model="radio">
          <el-radio label="1">无</el-radio>
          <el-radio label="2">有</el-radio>
        </el-radio-group>
      </div>
      <div class="add-line">
        <div class="add-key add-key4">
          <span>引流管</span>
        </div>
        <el-radio-group v-model="radio">
          <el-radio label="1">胃管</el-radio>
          <el-radio label="2">尿管</el-radio>
          <el-radio label="3">胸腔闭式引流</el-radio>
          <el-radio label="4">T管</el-radio>
          <el-radio label="5">腹腔引流</el-radio>
          <el-radio label="6">其他</el-radio>
        </el-radio-group>
      </div>
      <div class="add-line">
        <div class="add-key add-key4">
          <span>外周静脉</span>
        </div>
        <el-radio-group v-model="radio">
          <el-radio label="1">留置针</el-radio>
          <el-radio label="2">头皮针</el-radio>
        </el-radio-group>
      </div>
      <div class="add-line">
        <div class="add-key add-key4">
          <span>深静脉</span>
        </div>
        <el-radio-group v-model="radio">
          <el-radio label="1">PICC</el-radio>
          <el-radio label="2">锁骨下静脉</el-radio>
          <el-radio label="3">颈内静脉</el-radio>
          <el-radio label="4">股静脉</el-radio>
          <el-radio label="5">输液港</el-radio>
          <el-radio label="6">其他</el-radio>
        </el-radio-group>
      </div>
      <div class="add-line">
        <div class="add-key add-key4">
          <span>人工气道</span>
        </div>
        <el-radio-group v-model="radio">
          <el-radio label="1">气管切开</el-radio>
          <el-radio label="2">气管插管</el-radio>
          <el-radio label="3">其他</el-radio>
        </el-radio-group>
      </div>
      <div class="add-line">
        <div class="add-key add-key4">
          <span>安全隐患</span>
        </div>
        <el-radio-group v-model="radio">
          <el-radio label="1">无</el-radio>
          <el-radio label="2">有</el-radio>
          <el-radio label="3">压疮</el-radio>
          <el-radio label="4">管路滑脱</el-radio>
          <el-radio label="5">跌倒</el-radio>
          <el-radio label="6">烫伤</el-radio>
          <el-radio label="7">其他</el-radio>
        </el-radio-group>
      </div>
      <div class="add-line">
        <div class="add-key add-key4">
          <span>疼痛</span>
        </div>
        <el-radio-group v-model="radio">
          <el-radio label="1">无</el-radio>
          <el-radio label="2">有</el-radio>
        </el-radio-group>
        <el-input v-model="input" placeholder="请输入"></el-input>
      </div>
      <div class="add-line">
        <div class="add-key add-key4">
          <span>专科评估</span>
        </div>
        <el-input type="textarea" :rows="2" placeholder="请输入" v-model="textarea">
        </el-input>
      </div>
      <div class="add-line">
        <div class="add-key add-key4">
          <span>护理记录</span>
        </div>
        <el-input type="textarea" :rows="2" placeholder="请输入" v-model="textarea">
        </el-input>
      </div>
    </div>
    <div class="add-button">
      <el-button type="primary" @click="close">保存</el-button>
      <el-button @click="close">取消</el-button>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      input: '',
      value: '',
      options: [],
      radio: '',
      value1: '',
      textarea: ''
    }
  },
  methods: {
    close () {
      this.$parent.showAdd = false
    }
  }
}
</script>

<style lang="less" scoped>
.add {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 88;
  padding: 0 20px;
  background-color: #fff;
  box-sizing: border-box;

  .add-scroll {
    overflow-y: scroll;
    width: 100%;
    height: calc(100% - 60px);

    &::-webkit-scrollbar {
      width: 0;
    }
  }

  .add-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 60px;
  }

  .add-line {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 15px 0;

    .add-key {
      min-width: 100px;
      white-space: nowrap;
      color: #999;
      font-size: 16px;
    }

    .add-key1 {
      margin-right: 20px;
      white-space: nowrap;
      color: #999;
      font-size: 16px;
    }

    .add-key2 {
      margin-left: 40px;
    }

    .add-key3 {
      width: 140px;
    }

    .add-key4 {
      width: auto;
      margin-right: 10px;
      white-space: nowrap;
      color: #999;
      font-size: 16px;
    }

    .el-radio-group,
    .el-radio {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    /deep/.el-radio__label {
      font-size: 16px;
    }

    /deep/.el-textarea__inner {
      width: 300px;
      margin-left: 10px;
      resize: none;
    }

    .el-input {
      width: 100px;
      margin-right: 20px;
    }
  }

  .add-line1 {
    padding-left: 50px;
    box-sizing: border-box;
  }
}
</style>
