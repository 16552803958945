<template>
  <div class="pop">
    <div class="pop-content">
      <div class="pop-head">
        <span>新增设备</span>
        <span class="pop-close el-icon-close" @click="close"></span>
      </div>
      <div class="pop-box">
        <div class="pop-line">
          <span class="key">设备类型</span>
          <el-select v-model="value" placeholder="请选择">
            <el-option
              v-for="item in sblxs"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="pop-line">
          <span class="key">品牌</span>
          <el-input v-model="input" placeholder="请输入品牌"></el-input>
        </div>
        <div class="pop-line">
          <span class="key">设备SN</span>
          <el-input v-model="input" placeholder="请输入设备SN"></el-input>
        </div>
        <div class="pop-line">
          <span class="key">数量</span>
          <el-input v-model="input" placeholder="请输入数量"></el-input>
        </div>
        <div class="pop-line">
          <span class="key">登记时间</span>
          <el-date-picker v-model="value1" type="date" placeholder="请选择日期">
          </el-date-picker>
        </div>
        <div class="pop-line">
          <span class="key">登记人</span>
          <el-input v-model="input" placeholder="请输入姓名"></el-input>
        </div>
        <div class="pop-line">
          <span class="key">状态</span>
          <el-select v-model="value" placeholder="请选择">
            <el-option
              v-for="item in status"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="pop-footer">
        <el-button type="primary">保存</el-button>
        <el-button type="primary" plain>取消</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data () {
    return {
      input: '',
      sblxs: Object.freeze([
        { value: '1', label: '输液器' },
        { value: '2', label: '输液监控器' },
        { value: '3', label: '注射器' }
      ]),
      status: Object.freeze([
        { value: '1', label: '正常' },
        { value: '2', label: '报废' }
      ]),
      value: '',
      value1: ''
    }
  },
  methods: {
    close () {
      this.$parent.showAdd = false
    }
  }
}
</script>

<style lang="less" scoped>
.pop {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 88;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 10px;
  background-color: transparent;
  box-sizing: border-box;

  .pop-content {
    width: 50%;
    height: 72%;
    background-color: #fff;
    border: 1px solid rgba(228, 228, 228, 1);
    box-sizing: border-box;

    .pop-head {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 50px;
      background-color: rgba(242, 242, 242, 1);
      padding: 0 20px;
      box-sizing: border-box;
      font-size: 16px;

      .pop-close {
        font-size: 20px;
        cursor: pointer;
      }
    }

    .pop-box {
      overflow-y: scroll;
      width: 100%;
      height: calc(100% - 100px);
      padding-left: 40px;
      box-sizing: border-box;

      &::-webkit-scrollbar {
        width: 0;
      }

      .pop-line {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 20px;
        color: #999999;
        font-size: 14px;

        .key {
          width: 100px;
          white-space: nowrap;
        }

        .el-input, .el-select {
          width: 200px;
        }
      }
    }

    .pop-footer {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-evenly;
      width: 100%;
      height: 50px;
    }
  }
}
</style>
