<template>
  <div class="update">
    <div class="update-head">
      <span>输液记录</span>
      <el-button type="primary" @click="close">返回</el-button>
    </div>
    <div class="update-box">
      <div class="title">
        <span>基本信息</span>
      </div>
      <div class="card">
        <div class="card-item">
          <span class="key">编号</span>
          <span class="value">20200908900</span>
        </div>
        <div class="card-item">
          <span class="key">性别</span>
          <el-select v-model="value" placeholder="请选择">
            <el-option v-for="item in status" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="card-item">
          <span class="key">负责护士</span>
          <el-input v-model="input" placeholder="请输入"></el-input>
        </div>
        <div class="card-item">
          <span class="key">总量</span>
          <el-input v-model="input" placeholder="请输入"></el-input>
          <span class="unit">ml</span>
        </div>
        <div class="card-item">
          <span class="key">滴速</span>
          <el-input v-model="input" placeholder="请输入"></el-input>
          <span class="unit">d/min</span>
        </div>
        <div class="card-item">
          <span class="key">时间</span>
          <el-input v-model="input" placeholder="请输入"></el-input>
          <span class="unit">min</span>
        </div>
        <div class="card-item">
          <span class="key">输液日期</span>
          <el-date-picker v-model="value1" type="date" placeholder="选择日期">
          </el-date-picker>
        </div>
      </div>
    </div>
    <div class="add-footer">
      <el-button type="primary" @click="close">保存</el-button>
      <el-button @click="close">取消</el-button>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      input: '',
      options: [],
      sexs: [{
        value: '1',
        label: '男'
      }, {
        value: '2',
        label: '女'
      }],
      value: '',
      value1: '',
      status: Object.freeze([
        { value: '1', label: '已完成' },
        { value: '2', label: '待完成' }
      ])
    }
  },
  methods: {
    close () {
      this.$parent.showAdd = false
    }
  }
}
</script>

<style lang="less" scoped>
.update {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 88;
  width: 100%;
  height: 100%;
  padding: 10px;
  background-color: #fff;
  box-sizing: border-box;

  .update-head {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 48px;
    padding-right: 40px;
    border-bottom: 1px solid rgba(0, 0, 0, .5);
    box-sizing: border-box;
    font-size: 20px;
  }

  .update-box {
    overflow-y: scroll;
    width: 100%;
    height: calc(100% - 120px);
    margin-top: 10px;

    &::-webkit-scrollbar {
      width: 0;
    }

    .title {
      width: 100%;
      height: 40px;
      line-height: 40px;
      padding-left: 20px;
      box-sizing: border-box;
      font-size: 18px;
    }

    .card {
      display: flex;
      flex-wrap: wrap;
      width: calc(100% - 40px);
      margin-left: 20px;
      padding: 20px 10px;
      border-bottom: 1px solid rgba(242, 247, 251, 1);
      box-sizing: border-box;

      .card-item {
        display: flex;
        align-items: center;
        width: calc(30% - 10px);
        margin: 10px 5px;
        font-size: 16px;

        .key {
          width: 90px;
          margin-right: 10px;
          text-align: right;
          color: #999999;
        }

        .red {
          color: red;
        }

        .value {
          width: calc(100% - 100px);
          word-wrap: break-word;
        }

        .unit {
          margin-left: 10px;
        }

        .el-input,
        .el-select {
          width: 200px;
        }
      }

      .card-item1 {
        display: flex;
        align-items: center;
        width: calc(60% - 10px);
        margin: 10px 5px;
        font-size: 16px;

        .key {
          width: 100px;
          margin-right: 10px;
          text-align: right;
          color: #999999;
        }

        .el-input {

        }
      }
    }
  }

  .update-footer {
    display: flex;
    align-items: center;
    width: 100%;
    height: 62px;
  }
}
</style>
