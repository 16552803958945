<template>
  <div class="run">
    <div class="run-title">
      <span>出院病人查询</span>
    </div>
    <div class="run-search">
      <div class="search-item">
        <span class="search-width">选择日期</span>
        <el-date-picker v-model="value" type="daterange" range-separator="至" start-placeholder="开始日期"
          end-placeholder="结束日期">
        </el-date-picker>
      </div>
    </div>
    <div class="run-table">
      <el-table ref="multipleTable" border :data="tableData" height="100%" stripe>
        <el-table-column label="床位"></el-table-column>
        <el-table-column prop="name" label="姓名">
        </el-table-column>
        <el-table-column label="性别">
        </el-table-column>
        <el-table-column label="年龄">
        </el-table-column>
        <el-table-column label="住院号">
        </el-table-column>
        <el-table-column label="入院日期">
        </el-table-column>
        <el-table-column label="出院日期">
        </el-table-column>
      </el-table>
    </div>
    <div class="run-footer">
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="1"
        :page-sizes="[100, 200, 300, 400]" :page-size="100" layout="total, sizes, prev, pager, next, jumper"
        :total="400">
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data () {
    return {
      value: '',
      tableData: [
        {},
        {},
        {}
      ]
    }
  },
  methods: {
    handleSizeChange (val) {
      console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      console.log(`当前页: ${val}`)
    }
  }
}
</script>

<style lang="less" scoped>
.run {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 10px;
  background-color: #fff;
  box-sizing: border-box;

  .run-title {
    width: 100%;
    height: 60px;
    line-height: 60px;
    border-bottom: 1px solid rgba(0, 0, 0, .1);
    box-sizing: border-box;
    font-size: 18px;
  }

  .run-search {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    height: 100px;

    .search-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 25%;
    }

    .search-width {
      width: 100px;
      text-align: center;
      color: #999999;
      font-size: 13px;
    }
  }

  .run-table {
    width: 100%;
    height: calc(100% - 240px);
    margin-top: 10px;

    /deep/.el-table__cell {
      text-align: center;
    }

    .yellow {
      background-color: rgba(255, 153, 0, 1);
    }

    .green {
      background-color: rgba(0, 153, 0, 1);
    }

    .red {
      background-color: rgba(255, 0, 0, 1);
    }

    .blue {
      background-color: rgba(37, 97, 239, 1);
    }
  }

  .run-footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 70px;
  }
}
</style>
