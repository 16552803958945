<template>
  <div class="update">
    <div class="update-head">
      <span>新增人员</span>
      <el-button type="primary" @click="close">返回</el-button>
    </div>
    <div class="update-box">
      <div class="title">
        <span>基本信息</span>
      </div>
      <div class="card">
        <div class="card-item">
          <span class="key">编号</span>
          <span class="value">20200908900</span>
        </div>
        <div class="card-item">
          <span class="key">姓名</span>
          <el-input v-model="input" placeholder="请输入"></el-input>
        </div>
        <div class="card-item">
          <span class="key">性别</span>
          <el-select v-model="value" placeholder="请选择">
            <el-option v-for="item in sexs" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="card-item">
          <span class="key">身份证号</span>
          <el-input v-model="input" placeholder="请输入"></el-input>
        </div>
        <div class="card-item">
          <span class="key">年龄</span>
          <el-input v-model="input" placeholder="请输入"></el-input>
        </div>
        <div class="card-item">
          <span class="key">手机号码<span class="red">*</span></span>
          <el-input v-model="input" placeholder="请输入"></el-input>
        </div>
        <div class="card-item1">
          <span class="key">住址</span>
          <el-input v-model="input" placeholder="请输入"></el-input>
        </div>
        <div class="card-item">
          <span class="key">出生日期</span>
          <el-date-picker v-model="value1" type="date" placeholder="选择日期">
          </el-date-picker>
        </div>
        <div class="card-item">
          <span class="key">民族</span>
          <el-select v-model="value" placeholder="请选择">
            <el-option v-for="item in mzs" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="card-item">
          <span class="key">文化程度</span>
          <el-select v-model="value" placeholder="请选择">
            <el-option v-for="item in whcds" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="card-item">
          <span class="key">婚姻状况</span>
          <el-select v-model="value" placeholder="请选择">
            <el-option v-for="item in hyqks" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="card-item">
          <span class="key">籍贯</span>
          <el-input v-model="input" placeholder="请输入"></el-input>
        </div>
        <div class="card-item1">
          <span class="key">费用类型</span>
          <el-checkbox-group v-model="checkList">
            <el-checkbox label="1">自费</el-checkbox>
            <el-checkbox label="2">社会医疗保险</el-checkbox>
            <el-checkbox label="3">商业保险</el-checkbox>
            <el-checkbox label="4">新农合</el-checkbox>
            <el-checkbox label="5">其他</el-checkbox>
          </el-checkbox-group>
        </div>
        <div class="card-item1">
          <span class="key">工作单位</span>
          <el-input v-model="input" placeholder="请输入"></el-input>
        </div>
        <div class="card-item">
          <span class="key">职业</span>
          <el-input v-model="input" placeholder="请输入"></el-input>
        </div>
        <div class="card-item">
          <span class="key">户籍类型</span>
          <el-select v-model="value" placeholder="请选择">
            <el-option v-for="item in hjlxs" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
      </div>

      <div class="title">
        <span>健康信息</span>
      </div>
      <div class="card">
        <div class="card-item">
          <span class="key">身高</span>
          <el-input v-model="input" placeholder="请输入"></el-input>
          <span class="unit">cm</span>
        </div>
        <div class="card-item">
          <span class="key">体重</span>
          <el-input v-model="input" placeholder="请输入"></el-input>
          <span class="unit">kg</span>
        </div>
        <div class="card-item">
          <span class="key">血型</span>
          <el-select v-model="value" placeholder="请选择">
            <el-option v-for="item in xxs" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="card-item">
          <span class="key">RH阴性</span>
          <el-select v-model="value" placeholder="请选择">
            <el-option v-for="item in rhs" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="card-item">
          <span class="key">过敏史</span>
          <el-radio-group v-model="radio">
            <el-radio label="1">无</el-radio>
            <el-radio label="2">有</el-radio>
          </el-radio-group>
        </div>
        <div class="card-item">
          <span class="key">既往史</span>
          <el-radio-group v-model="radio">
            <el-radio label="1">无</el-radio>
            <el-radio label="2">有</el-radio>
          </el-radio-group>
        </div>
        <div class="card-item">
          <span class="key">就诊史</span>
          <el-radio-group v-model="radio">
            <el-radio label="1">无</el-radio>
            <el-radio label="2">有</el-radio>
          </el-radio-group>
        </div>
        <div class="card-item">
          <span class="key">家族病史</span>
          <el-radio-group v-model="radio">
            <el-radio label="1">无</el-radio>
            <el-radio label="2">有</el-radio>
          </el-radio-group>
        </div>
        <div class="card-item">
          <span class="key">体温</span>
          <el-input v-model="input" placeholder="请输入"></el-input>
          <span class="unit">℃</span>
        </div>
        <div class="card-item">
          <span class="key">血糖</span>
          <el-input v-model="input" placeholder="请输入"></el-input>
          <span class="unit">mmol/L</span>
        </div>
        <div class="card-item">
          <span class="key">血压</span>
          <el-input v-model="input" placeholder="请输入"></el-input>
          <span class="unit">mmHg</span>
        </div>
      </div>

      <div class="title">
        <span>住院信息</span>
      </div>
      <div class="card">
        <div class="card-item">
          <span class="key">住院号</span>
          <el-input v-model="input" placeholder="请输入"></el-input>
        </div>
        <div class="card-item">
          <span class="key">床位</span>
          <el-input v-model="input" placeholder="请输入"></el-input>
        </div>
        <div class="card-item">
          <span class="key">病房</span>
          <el-select v-model="value" placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="card-item">
          <span class="key">入院时间</span>
          <el-date-picker v-model="value1" type="date" placeholder="选择日期">
          </el-date-picker>
        </div>
        <div class="card-item">
          <span class="key">入住科室</span>
          <el-select v-model="value" placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="card-item">
          <span class="key">诊断</span>
          <el-input v-model="input" placeholder="请输入"></el-input>
        </div>
        <div class="card-item">
          <span class="key">主治医生</span>
          <el-input v-model="input" placeholder="请输入"></el-input>
        </div>
        <div class="card-item">
          <span class="key">护理级别</span>
          <el-select v-model="value" placeholder="请选择">
            <el-option v-for="item in hljbs" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="card-item">
          <span class="key">护士</span>
          <el-input v-model="input" placeholder="请输入"></el-input>
        </div>
        <div class="card-item">
          <span class="key">护理员</span>
          <el-input v-model="input" placeholder="请输入"></el-input>
        </div>
        <div class="card-item">
          <span class="key">医保费别</span>
          <el-select v-model="value" placeholder="请选择">
            <el-option v-for="item in ybfbs" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="card-item"></div>
        <div class="card-item">
          <span class="key">预交款</span>
          <el-input v-model="input" placeholder="请输入"></el-input>
          <span class="unit">元</span>
        </div>
        <div class="card-item">
          <span class="key">费用合计</span>
          <el-input v-model="input" placeholder="请输入"></el-input>
          <span class="unit">元</span>
        </div>
        <div class="card-item">
          <span class="key">自付费用</span>
          <el-input v-model="input" placeholder="请输入"></el-input>
          <span class="unit">元</span>
        </div>
      </div>
    </div>
    <div class="update-footer">
      <el-button type="primary" @click="close">保存</el-button>
      <el-button @click="close">取消</el-button>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      input: '',
      options: [],
      sexs: [{
        value: '1',
        label: '男'
      }, {
        value: '2',
        label: '女'
      }],
      value: '',
      value1: '',
      mzs: Object.freeze([
        { value: '1', label: '汉族' },
        { value: '2', label: '回族' },
        { value: '3', label: '维吾尔族' },
        { value: '4', label: '壮族' },
        { value: '5', label: '其他少数民族' }
      ]),
      whcds: Object.freeze([
        { value: '1', label: '小学' },
        { value: '2', label: '初中' },
        { value: '3', label: '高中/中专' },
        { value: '4', label: '大专及以上' },
        { value: '5', label: '不详' }
      ]),
      hyqks: Object.freeze([
        { value: '1', label: '未婚' },
        { value: '2', label: '已婚' },
        { value: '3', label: '离异' },
        { value: '4', label: '丧偶' },
        { value: '5', label: '不详' }
      ]),
      checkList: [],
      hjlxs: Object.freeze([
        { value: '1', label: '农村' },
        { value: '2', label: '城镇' }
      ]),
      xxs: Object.freeze([
        { value: '1', label: 'A型' },
        { value: '2', label: 'B型' },
        { value: '3', label: 'AB型' },
        { value: '4', label: 'O型' },
        { value: '5', label: '不详' }
      ]),
      rhs: Object.freeze([
        { value: '1', label: '是' },
        { value: '2', label: '否' },
        { value: '3', label: '不详' }
      ]),
      radio: '',
      rzkss: Object.freeze([
        { value: '1', label: '肝胆胰腺外科' },
        { value: '2', label: '肿瘤科' },
        { value: '3', label: '心内科' },
        { value: '4', label: '骨科' },
        { value: '5', label: '妇科' }
      ]),
      hljbs: Object.freeze([
        { value: '1', label: '特级护理' },
        { value: '2', label: '一级护理' },
        { value: '3', label: '二级护理' },
        { value: '4', label: '三级护理' }
      ]),
      ybfbs: Object.freeze([
        { value: '1', label: '现金' },
        { value: '2', label: '微信' },
        { value: '3', label: '支付宝' },
        { value: '4', label: '信用卡' },
        { value: '5', label: '银行卡' }
      ])
    }
  },
  methods: {
    close () {
      this.$parent.showUpdate = false
    }
  }
}
</script>

<style lang="less" scoped>
.update {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 88;
  width: 100%;
  height: 100%;
  padding: 10px;
  background-color: #fff;
  box-sizing: border-box;

  .update-head {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 48px;
    padding-right: 40px;
    border-bottom: 1px solid rgba(0, 0, 0, .5);
    box-sizing: border-box;
    font-size: 20px;
  }

  .update-box {
    overflow-y: scroll;
    width: 100%;
    height: calc(100% - 120px);
    margin-top: 10px;

    &::-webkit-scrollbar {
      width: 0;
    }

    .title {
      width: 100%;
      height: 40px;
      line-height: 40px;
      padding-left: 20px;
      box-sizing: border-box;
      font-size: 18px;
    }

    .card {
      display: flex;
      flex-wrap: wrap;
      width: calc(100% - 40px);
      margin-left: 20px;
      padding: 20px 10px;
      border-bottom: 1px solid rgba(242, 247, 251, 1);
      box-sizing: border-box;

      .card-item {
        display: flex;
        align-items: center;
        width: calc(30% - 10px);
        margin: 10px 5px;
        font-size: 16px;

        .key {
          width: 90px;
          margin-right: 10px;
          text-align: right;
          color: #999999;
        }

        .red {
          color: red;
        }

        .value {
          width: calc(100% - 100px);
          word-wrap: break-word;
        }

        .unit {
          margin-left: 10px;
        }

        .el-input,
        .el-select {
          width: 200px;
        }
      }

      .card-item1 {
        display: flex;
        align-items: center;
        width: calc(60% - 10px);
        margin: 10px 5px;
        font-size: 16px;

        .key {
          width: 100px;
          margin-right: 10px;
          text-align: right;
          color: #999999;
        }

        .el-input {

        }
      }
    }
  }

  .update-footer {
    display: flex;
    align-items: center;
    width: 100%;
    height: 62px;
  }
}
</style>
