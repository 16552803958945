<template>
  <div class="pop">
    <div class="pop-content">
      <div class="pop-head">
        <span>新增</span>
        <span class="pop-close el-icon-close" @click="close"></span>
      </div>
      <div class="pop-box">
        <div class="pop-line">
          <span class="key">床位</span>
          <el-input v-model="input" placeholder="请输入"></el-input>
        </div>
        <div class="pop-line">
          <span class="key">姓名</span>
          <el-input v-model="input" placeholder="请输入"></el-input>
        </div>
        <div class="pop-line">
          <span class="key">性别</span>
          <el-radio-group v-model="radio">
            <el-radio label="1">男</el-radio>
            <el-radio label="2">女</el-radio>
          </el-radio-group>
        </div>
        <div class="pop-line">
          <span class="key">年龄</span>
          <el-input v-model="input" placeholder="请输入"></el-input>
        </div>
        <div class="pop-line">
          <span class="key">诊断</span>
          <el-input v-model="input" placeholder="请输入"></el-input>
        </div>
        <div class="pop-line">
          <span class="key">描述</span>
          <el-input v-model="input" placeholder="请输入"></el-input>
        </div>
        <div class="pop-line">
          <span class="key">备注</span>
          <el-input v-model="input" placeholder="请输入"></el-input>
        </div>
      </div>
      <div class="pop-footer">
        <el-button type="primary">保存</el-button>
        <el-button type="primary" plain>取消</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data () {
    return {
      input: '',
      radio: ''
    }
  },
  methods: {
    close () {
      this.$parent.showAdd = false
    }
  }
}
</script>

<style lang="less" scoped>
.pop {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 88;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 10px;
  background-color: transparent;
  box-sizing: border-box;

  .pop-content {
    width: 50%;
    height: 72%;
    background-color: #fff;
    border: 1px solid rgba(228, 228, 228, 1);
    box-sizing: border-box;

    .pop-head {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 50px;
      background-color: rgba(242, 242, 242, 1);
      padding: 0 20px;
      box-sizing: border-box;
      font-size: 16px;

      .pop-close {
        font-size: 20px;
        cursor: pointer;
      }
    }

    .pop-box {
      overflow-y: scroll;
      width: 100%;
      height: calc(100% - 100px);
      padding-left: 40px;
      box-sizing: border-box;

      &::-webkit-scrollbar {
        width: 0;
      }

      .pop-line {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 20px;
        color: #999999;
        font-size: 14px;

        .key {
          width: 100px;
          white-space: nowrap;
        }

        .el-input, .el-select {
          width: 200px;
        }
      }
    }

    .pop-footer {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-evenly;
      width: 100%;
      height: 50px;
    }
  }
}
</style>
