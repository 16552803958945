<template>
  <div class="run">
    <div class="run-box">
      <div class="search">
        <div class="search-item">
          <span class="search-width">分组：</span>
          <el-radio-group v-model="radio">
            <el-radio label="1">所有病人</el-radio>
            <el-radio label="2">1组</el-radio>
            <el-radio label="3">2组</el-radio>
            <el-radio label="4">3组</el-radio>
            <el-radio label="5">未分组</el-radio>
          </el-radio-group>
        </div>
        <div class="search-item">
          <span class="search-width">分类：</span>
          <el-radio-group v-model="radio1">
            <el-radio label="1">新病人(10人)</el-radio>
            <el-radio label="2">过敏史(3人)</el-radio>
            <el-radio label="3">新医嘱(3人)</el-radio>
            <el-radio label="4">手术病人(3人)</el-radio>
            <el-radio label="5">欠费(3人)</el-radio>
            <el-radio label="6">高温病人(3人)</el-radio>
          </el-radio-group>
        </div>
        <div class="search-item">
          <span class="search-width">护理级别：</span>
          <el-radio-group v-model="radio2">
            <el-radio label="1">特级护理(10人)</el-radio>
            <el-radio label="2">一级护理(10人)</el-radio>
            <el-radio label="3">二级护理(10人)</el-radio>
            <el-radio label="4">三级护理(10人)</el-radio>
          </el-radio-group>
        </div>
      </div>
      <div class="icon">
        <span class="el-icon-s-operation item" :class="{ 'active': iconIndex === 1 }" @click="tabClick(1)"></span>
        <span class="el-icon-menu item" :class="{ 'active': iconIndex === 0 }" @click="tabClick(0)"></span>
      </div>
      <div class="label" v-show="iconIndex === 0">
        <div class="label-item" slot="reference" v-for="(item, index) in 10" :key="index">
          <div class="label-head" @click="dialogTableVisible = true">
            <div class="label-card"></div>
            <div class="label-info">
              <div class="label-info-item">
                <span class="name">张然</span>
                <span class="age">66岁</span>
                <span class="sex">♀</span>
              </div>
              <div class="label-info-item">
                <span class="phone">联系电话：15803339294</span>
              </div>
            </div>
            <div class="label-tag red">
              <span>特级护理</span>
            </div>
          </div>
          <div class="label-content" @click="dialogTableVisible = true">
            <div class="label-content-item">
              <span>病房:302</span>
              <span>床位:02</span>
              <span>住院号:004332558000</span>
            </div>
            <div class="label-content-item">
              <span>医生：崔风超</span>
              <span>入院时间：2024-05-23</span>
            </div>
            <div class="label-content-item">
              <span>护士：李风超</span>
              <span>护理员：王岚</span>
            </div>
          </div>
          <div class="label-footer">
            <div class="label-footer-item">
              <span>删除</span>
            </div>
            <div class="label-footer-item" @click="openPop">
              <span>查看信息</span>
            </div>
          </div>
        </div>
      </div>
      <div class="table" v-show="iconIndex === 1">
        <el-table ref="multipleTable" border :data="tableData" height="100%" stripe>
          <el-table-column type="selection" width="55">
          </el-table-column>
          <el-table-column label="床位"></el-table-column>
          <el-table-column prop="name" label="姓名">
          </el-table-column>
          <el-table-column label="性别">
          </el-table-column>
          <el-table-column label="年龄">
          </el-table-column>
          <el-table-column label="护理等级">
          </el-table-column>
          <el-table-column label="住院号">
          </el-table-column>
          <el-table-column label="联系电话">
          </el-table-column>
          <el-table-column label="住址">
          </el-table-column>
          <el-table-column label="操作">
            <!-- slot-scope="scope" -->
            <template>
              <el-button size="mini" type="primary" @click="openPop">查看详情</el-button>
              <el-button size="mini" type="danger" @click="del">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div class="run-footer">
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="1"
        :page-sizes="[100, 200, 300, 400]" :page-size="100" layout="total, sizes, prev, pager, next, jumper"
        :total="400">
      </el-pagination>
    </div>
    <el-dialog title="" :visible.sync="dialogTableVisible">
      <el-descriptions title="">
        <el-descriptions-item label="床号">01</el-descriptions-item>
        <el-descriptions-item label="住院号">00916279</el-descriptions-item>
        <el-descriptions-item label="姓名">张三</el-descriptions-item>
        <el-descriptions-item label="性别">男</el-descriptions-item>
        <el-descriptions-item label="年龄">66</el-descriptions-item>
        <el-descriptions-item label="身份证号">330632194801010000</el-descriptions-item>
        <el-descriptions-item label="联系电话">18811111111</el-descriptions-item>
        <el-descriptions-item label="入院时间">2024-05-10  10：10</el-descriptions-item>
        <el-descriptions-item label="诊断">肝占位</el-descriptions-item>
        <el-descriptions-item label="临床科室">肝胆外科</el-descriptions-item>
        <el-descriptions-item label="主治医生">张超</el-descriptions-item>
        <el-descriptions-item label="饮食">低脂食品</el-descriptions-item>
        <el-descriptions-item label="护理级别">一级护理</el-descriptions-item>
        <el-descriptions-item label="过敏史">无</el-descriptions-item>
        <el-descriptions-item label="医保类别">自费</el-descriptions-item>
        <el-descriptions-item label="预交款">5000.00元</el-descriptions-item>
        <el-descriptions-item label="费用合计">5000.00元</el-descriptions-item>
        <el-descriptions-item label="自费费用">5000.00元</el-descriptions-item>
      </el-descriptions>
    </el-dialog>
    <Pop v-if="showPop"></Pop>
  </div>
</template>

<script>
import Pop from './pop.vue'
export default {
  components: {
    Pop
  },
  data () {
    return {
      radio: '',
      radio1: '',
      radio2: '',
      iconIndex: 0,
      tableData: [
        {},
        {},
        {}
      ],
      showUpdate: false,
      showPop: false,
      dialogTableVisible: false
    }
  },
  methods: {
    handleSizeChange (val) {
      console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      console.log(`当前页: ${val}`)
    },
    tabClick (index) {
      this.iconIndex = index
    },
    openAdd () {
      this.showUpdate = true
    },
    openPop () {
      this.showPop = true
    },
    del () {
      this.$message({
        message: '删除成功',
        type: 'success'
      })
    }
  }
}
</script>

<style lang="less" scoped>
.run {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0 10px;
  background-color: rgba(242, 242, 242, 1);
  box-sizing: border-box;

  .run-box {
    width: 100%;
    height: calc(100% - 80px);
    margin-bottom: 10px;
    background-color: #fff;

    .search {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      width: 100%;
      height: 160px;

      .search-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
      }

      .search-width {
        width: 110px;
        text-align: right;
        font-size: 16px;
      }

      /deep/.el-radio__input {
        display: none;
      }

      /deep/.el-radio__label {
        font-size: 16px;
      }
    }

    .icon {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      width: 100%;
      height: 60px;
      padding-right: 40px;
      box-sizing: border-box;
      color: #999999;
      font-size: 20px;

      .item {
        margin-right: 20px;
        cursor: pointer;
      }

      .active {
        color: #409EFF;
      }
    }

    .label {
      overflow-y: scroll;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      width: 100%;
      height: calc(100% - 220px);
      padding: 10px;
      box-sizing: border-box;

      &::-webkit-scrollbar {
        width: 0;
      }

      .label-item {
        position: relative;
        display: flex;
        flex-direction: column;
        width: calc(25% - 20px);
        height: 240px;
        margin: 10px;
        padding-top: 10px;
        background-color: #fff;
        border-radius: 8px;
        border: 1px solid rgba(242, 242, 242, 1);
        box-shadow: 0px 0px 15px rgba(242, 242, 242, 1);
        box-sizing: border-box;
        cursor: pointer;

        .label-head {
          display: flex;
          flex-direction: row;
          width: 100%;
          height: 60px;
          padding: 0 10px;
          box-sizing: border-box;

          .label-card {
            width: 60px;
            height: 60px;
            border-radius: 50%;
            background-color: #8bbee6;
          }

          .label-info {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: calc(100% - 140px);
            height: 60px;

            .label-info-item {
              display: flex;
              flex-direction: row;
              align-items: center;
            }
          }

          .name {
            margin-left: 10px;
            font-size: 18px;
          }

          .age {
            margin-left: 10px;
            font-size: 12px;
          }

          .sex {
            margin-left: 10px;
            font-size: 12px;
          }

          .phone {
            margin-left: 10px;
            font-size: 14px;
          }

          .label-tag {
            height: 20px;
            color: #fff;
            font-size: 14px;
          }

          .yellow {
            background-color: rgba(255, 153, 0, 1);
          }

          .green {
            background-color: rgba(0, 153, 0, 1);
          }

          .red {
            background-color: rgba(255, 0, 0, 1);
          }

          .blue {
            background-color: rgba(37, 97, 239, 1);
          }
        }

        .label-content {
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          width: 100%;
          height: calc(100% - 96px);

          .label-content-item {
            display: flex;
            font-size: 14px;

            span {
              margin: 0 10px;
            }
          }
        }

        .label-footer {
          display: flex;
          flex-direction: row;
          width: 100%;
          height: 36px;
          border-top: 1px solid rgba(0, 0, 0, .1);
          box-sizing: border-box;

          .label-footer-item {
            width: 50%;
            height: 36px;
            line-height: 36px;
            text-align: center;
            font-size: 16px;
          }

          .label-footer-item:last-child {
            border-left: 1px solid rgba(0, 0, 0, .1);
            box-sizing: border-box;
            color: rgba(37, 97, 239, 1);
          }
        }
      }

      .label-item:hover {
        background-color: rgba(242, 247, 251, 1);
      }
    }

    .table {
      width: 100%;
      height: calc(100% - 220px);
      padding: 10px;
      box-sizing: border-box;

      /deep/.el-table__cell {
        text-align: center
      }

      .yellow {
        background-color: rgba(255, 153, 0, 1);
      }

      .green {
        background-color: rgba(0, 153, 0, 1);
      }

      .red {
        background-color: rgba(255, 0, 0, 1);
      }

      .blue {
        background-color: rgba(37, 97, 239, 1);
      }
    }
  }

  .run-footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 70px;
  }

  /deep/.el-descriptions-item__label, /deep/.el-descriptions-item__content {
    font-size: 16px;
  }
}
</style>
