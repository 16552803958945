<template>
  <div class="update">
    <div class="update-head">
      <span>新增人员</span>
      <el-button type="primary" @click="close">返回</el-button>
    </div>
    <div class="update-box">
      <div class="title">
        <span>基本信息</span>
      </div>
      <div class="card">
        <div class="card-item">
          <span class="key">床号:</span>
          <span class="value">01</span>
        </div>
        <div class="card-item">
          <span class="key">姓名:</span>
          <span class="value">张三</span>
        </div>
        <div class="card-item">
          <span class="key">日期:</span>
          <span class="value">2014-05-23</span>
        </div>
        <div class="card-item">
          <span class="key">时段</span>
          <el-select v-model="value" placeholder="请选择">
            <el-option v-for="item in sexs" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="card-item">
          <span class="key">录入人</span>
          <el-input v-model="input" placeholder="请输入"></el-input>
        </div>
      </div>

      <div class="title">
        <span>TPRBP</span>
      </div>
      <div class="card">
        <div class="card-item">
          <span class="key">体温</span>
          <el-select v-model="value" placeholder="请选择">
            <el-option v-for="item in xxs" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
          <el-input v-model="input" placeholder="请输入"></el-input>
          <span class="unit">℃</span>
        </div>
        <div class="card-item">
          <span class="key">降温后</span>
          <el-input v-model="input" placeholder="请输入"></el-input>
          <span class="unit">℃</span>
        </div>
        <div class="card-item">
          <span class="key">脉搏</span>
          <el-select v-model="value" placeholder="请选择">
            <el-option v-for="item in rhs" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
          <el-input v-model="input" placeholder="请输入"></el-input>
          <span class="unit">次/分</span>
        </div>
        <div class="card-item">
          <span class="key">心率</span>
          <el-input v-model="input" placeholder="请输入"></el-input>
          <span class="unit">次/分</span>
        </div>
        <div class="card-item">
          <span class="key">呼吸</span>
          <el-select v-model="value" placeholder="请选择">
            <el-option v-for="item in hxs" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
          <el-input v-model="input" placeholder="请输入"></el-input>
          <span class="unit">次/分</span>
        </div>
        <div class="card-item">
          <span class="key">血压</span>
          <el-input v-model="input" placeholder="请输入"></el-input>
          <span>/</span>
          <el-input v-model="input" placeholder="请输入"></el-input>
          <span class="unit">mmHg</span>
        </div>
        <div class="card-item">
          <span class="key">药物过敏</span>
          <el-select v-model="value" placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
          <el-input v-model="input" placeholder="请输入"></el-input>
        </div>
      </div>

      <div class="title">
        <span>住院信息</span>
      </div>
      <div class="card">
        <div class="card-item">
          <span class="key">血氧饱和度</span>
          <el-input v-model="input" placeholder="请输入"></el-input>
          <span class="unit">%</span>
        </div>
        <div class="card-item">
          <span class="key">血糖</span>
          <el-select v-model="value" placeholder="请选择">
            <el-option v-for="item in xts" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
          <span class="unit">mmol/L</span>
        </div>
        <div class="card-item">
          <span class="key">心率</span>
          <el-input v-model="input" placeholder="请输入"></el-input>
          <span class="unit">次/分</span>
        </div>
      </div>

      <div class="title">
        <span>出入量</span>
      </div>
      <div class="card">
        <div class="card-item">
          <span class="key">大便</span>
          <el-select v-model="value" placeholder="请选择">
            <el-option v-for="item in dbs" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
          <el-input v-model="input" placeholder="请输入"></el-input>
          <span class="unit">次</span>
        </div>
        <div class="card-item">
          <span class="key">尿量</span>
          <el-select v-model="value" placeholder="请选择">
            <el-option v-for="item in nls" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
          <el-input v-model="input" placeholder="请输入"></el-input>
          <span class="unit">ml</span>
        </div>
        <div class="card-item">
          <span class="key">入量</span>
          <el-input v-model="input" placeholder="请输入"></el-input>
          <span class="unit">ml</span>
        </div>
        <div class="card-item">
          <span class="key">出量</span>
          <el-input v-model="input" placeholder="请输入"></el-input>
          <span class="unit">ml</span>
        </div>
      </div>

      <div class="title">
        <span>自定义体征项</span>
      </div>
      <div class="card">
        <div class="card-item">
          <el-select v-model="value" placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
          <el-input v-model="input" placeholder="请输入"></el-input>
        </div>
        <div class="card-item">
          <el-select v-model="value" placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
          <el-input v-model="input" placeholder="请输入"></el-input>
        </div>
        <div class="card-item">
          <el-select v-model="value" placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
          <el-input v-model="input" placeholder="请输入"></el-input>
        </div>
      </div>

      <div class="title">
        <span>病人事件</span>
      </div>
      <div class="card">
        <div class="card-item">
          <el-select v-model="value" placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
      </div>
    </div>

    <div class="update-footer">
      <el-button type="primary" @click="close">保存</el-button>
      <el-button @click="close">取消</el-button>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      input: '',
      options: [],
      sexs: [{
        value: '1',
        label: '11'
      }, {
        value: '2',
        label: '12'
      }],
      value: '',
      value1: '',
      mzs: Object.freeze([
        { value: '1', label: '汉族' },
        { value: '2', label: '回族' },
        { value: '3', label: '维吾尔族' },
        { value: '4', label: '壮族' },
        { value: '5', label: '其他少数民族' }
      ]),
      whcds: Object.freeze([
        { value: '1', label: '小学' },
        { value: '2', label: '初中' },
        { value: '3', label: '高中/中专' },
        { value: '4', label: '大专及以上' },
        { value: '5', label: '不详' }
      ]),
      hyqks: Object.freeze([
        { value: '1', label: '未婚' },
        { value: '2', label: '已婚' },
        { value: '3', label: '离异' },
        { value: '4', label: '丧偶' },
        { value: '5', label: '不详' }
      ]),
      checkList: [],
      hjlxs: Object.freeze([
        { value: '1', label: '农村' },
        { value: '2', label: '城镇' }
      ]),
      xxs: Object.freeze([
        { value: '1', label: '腋温' },
        { value: '2', label: '口温' },
        { value: '3', label: '肛温' }
      ]),
      rhs: Object.freeze([
        { value: '1', label: '脉搏' }
      ]),
      radio: '',
      rzkss: Object.freeze([
        { value: '1', label: '肝胆胰腺外科' },
        { value: '2', label: '肿瘤科' },
        { value: '3', label: '心内科' },
        { value: '4', label: '骨科' },
        { value: '5', label: '妇科' }
      ]),
      hljbs: Object.freeze([
        { value: '1', label: '特级护理' },
        { value: '2', label: '一级护理' },
        { value: '3', label: '二级护理' },
        { value: '4', label: '三级护理' }
      ]),
      ybfbs: Object.freeze([
        { value: '1', label: '现金' },
        { value: '2', label: '微信' },
        { value: '3', label: '支付宝' },
        { value: '4', label: '信用卡' },
        { value: '5', label: '银行卡' }
      ]),
      hxs: Object.freeze([
        { value: '1', label: '呼吸机' }
      ]),
      xts: Object.freeze([
        { value: '1', label: '早上' },
        { value: '2', label: '中午' },
        { value: '3', label: '晚上' }
      ]),
      dbs: Object.freeze([
        { value: '1', label: '灌肠' },
        { value: '2', label: '人工肛门' },
        { value: '3', label: '大便失禁' }
      ]),
      nls: Object.freeze([
        { value: '1', label: '小便失禁' }
      ])
    }
  },
  methods: {
    close () {
      this.$parent.showUpdate = false
    }
  }
}
</script>

<style lang="less" scoped>
.update {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 88;
  width: 100%;
  height: 100%;
  padding: 10px;
  background-color: #fff;
  box-sizing: border-box;

  .update-head {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 48px;
    padding-right: 40px;
    border-bottom: 1px solid rgba(0, 0, 0, .5);
    box-sizing: border-box;
    font-size: 20px;
  }

  .update-box {
    overflow-y: scroll;
    width: 100%;
    height: calc(100% - 120px);
    margin-top: 10px;

    &::-webkit-scrollbar {
      width: 0;
    }

    .title {
      width: 100%;
      height: 40px;
      line-height: 40px;
      padding-left: 20px;
      box-sizing: border-box;
      font-size: 18px;
    }

    .card {
      display: flex;
      flex-wrap: wrap;
      width: calc(100% - 40px);
      margin-left: 20px;
      padding: 20px 10px;
      border-bottom: 1px solid rgba(242, 247, 251, 1);
      box-sizing: border-box;

      .card-item {
        display: flex;
        align-items: center;
        width: calc(25% - 10px);
        margin: 10px 5px;
        font-size: 16px;

        .key {
          width: 90px;
          margin-right: 10px;
          text-align: right;
          color: #999999;
        }

        .red {
          color: red;
        }

        .value {
          width: calc(100% - 100px);
          word-wrap: break-word;
        }

        .unit {
          margin-left: 10px;
        }

        .el-input,
        .el-select {
          width: 100px;
        }

        >span {
          white-space: nowrap;
        }
      }

      .card-item1 {
        display: flex;
        align-items: center;
        width: calc(60% - 10px);
        margin: 10px 5px;
        font-size: 16px;

        .key {
          width: 100px;
          margin-right: 10px;
          text-align: right;
          color: #999999;
        }

        .el-input {

        }
      }
    }
  }

  .update-footer {
    display: flex;
    align-items: center;
    width: 100%;
    height: 62px;
  }
}
</style>
