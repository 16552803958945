<template>
  <div class="enter">
    <div class="enter-head">
      <span>体温单录入</span>
      <el-button type="primary" @click="close">返回</el-button>
    </div>
    <div class="enter-box">
      <div class="enter-left">
        <div class="enter-title">
          <span>选择患者</span>
        </div>
        <div class="enter-search">
          <div class="item-search">
            <span class="span">床位</span>
            <el-input v-model="input" placeholder="请输入床位"></el-input>
          </div>
          <div class="item-search">
            <span class="span">姓名</span>
            <el-input v-model="input" placeholder="请输入姓名"></el-input>
          </div>
          <div class="item-search">
            <span class="span">病人</span>
            <el-select v-model="value" placeholder="请选择">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="enter-table">
          <el-table :data="tableData" stripe border height="100%">
            <el-table-column type="selection" width="55">
            </el-table-column>
            <el-table-column prop="name" label="床位">
            </el-table-column>
            <el-table-column prop="address" label="姓名">
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="enter-right">
        <div class="enter-title">
          <span>打印设置</span>
        </div>
        <div class="enter-print">
          <div class="print-item">
            <span>第</span>
            <el-select v-model="value" placeholder="请选择">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
            <span>周</span>
          </div>
          <div class="print-item">
            <span>系数</span>
            <el-select v-model="value" placeholder="请选择">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </div>
          <el-radio-group v-model="radio">
            <el-radio label="1">合适高度</el-radio>
            <el-radio label="2">实际大小</el-radio>
          </el-radio-group>
          <div class="print-item" @click="showUpdate = true">
            <span class="icon el-icon-edit-outline"></span>
            <span>录入</span>
          </div>
          <div class="print-item">
            <span class="icon el-icon-printer"></span>
            <span>打印</span>
          </div>
        </div>
        <div class="enter-info">
          <span>床位：01</span>
          <span>姓名：张三</span>
          <span>科室：肝胆外科</span>
          <span>入院时间：2024-05-06</span>
          <span>住院号：0091679</span>
        </div>
        <div class="enter-table"></div>
      </div>
    </div>
    <Update v-if="showUpdate"></Update>
  </div>
</template>

<script>
import Update from './update.vue'
export default {
  components: {
    Update
  },
  data () {
    return {
      input: '',
      value: '',
      options: [],
      radio: '',
      tableData: [
        {},
        {},
        {}
      ],
      showUpdate: false
    }
  },
  methods: {
    close () {
      this.$parent.showEnter = false
    }
  }
}
</script>

<style lang="less" scoped>
.enter {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 888;
  width: 100%;
  height: 100%;
  padding: 10px;
  background-color: #fff;
  box-sizing: border-box;

  .enter-head {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 48px;
    padding-right: 40px;
    border-bottom: 1px solid rgba(0, 0, 0, .1);
    box-sizing: border-box;
    font-size: 20px;
  }

  .enter-box {
    display: flex;
    width: 100%;
    height: calc(100% - 58px);
    margin-top: 10px;

    .enter-left {
      width: 380px;
      height: 100%;
      border-radius: 8px;
      border: 1px solid rgba(242, 242, 242, 1);
      box-shadow: 0px 0px 15px rgba(242, 242, 242, 1);
      box-sizing: border-box;

      .enter-search {
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-wrap: wrap;
        width: 100%;
        height: 120px;

        .item-search {
          display: flex;
          flex-direction: row;
          align-items: center;
          width: calc(50% - 20px);
          height: 50px;
          margin: 0 10px;
        }

        .span {
          margin-right: 10px;
          white-space: nowrap;
          font-size: 16px;
        }
      }
    }

    .enter-right {
      width: calc(100% - 400px);
      height: 100%;
      margin-left: 20px;
      border-radius: 8px;
      border: 1px solid rgba(242, 242, 242, 1);
      box-shadow: 0px 0px 15px rgba(242, 242, 242, 1);
      box-sizing: border-box;

      .enter-print {
        display: flex;
        align-items: center;
        justify-content: space-around;
        width: 100%;
        height: 60px;
        padding-right: 80px;
        box-sizing: border-box;
        color: #999;
        font-size: 16px;

        .print-item {
          display: flex;
          flex-direction: row;
          align-items: center;
          cursor: pointer;
          font-size: 16px;

          .el-select {
            width: 160px;
          }

          .icon {
            font-size: 24px;
          }
        }

        /deep/.el-radio__label {
          font-size: 16px;
        }
      }

      .enter-info {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        width: 100%;
        height: 60px;
        font-size: 16px;
      }
    }

    .enter-title {
      width: 100%;
      height: 50px;
      line-height: 50px;
      text-align: center;
      font-size: 16px;
    }

    .enter-table {
      width: 100%;
      height: calc(100% - 170px);

      /deep/.el-table__cell {
        text-align: center;
      }
    }
  }
}
</style>
