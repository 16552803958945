<template>
  <div class="run">
    <div class="run-box">
      <div class="search">
        <div class="search-item">
          <span class="search-width">机构</span>
          <el-select v-model="value" placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
          <el-select v-model="value" placeholder="请选择下属机构">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="search-item">
          <span class="search-width">病房</span>
          <el-select v-model="value" placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="search-item">
          <span class="search-width">姓名</span>
          <el-input v-model="input" placeholder="请输入姓名"></el-input>
        </div>
        <div class="search-item">
          <span class="search-width">身份证号</span>
          <el-input v-model="input" placeholder="请输入身份证号"></el-input>
        </div>
        <div class="search-item">
          <div class="search-width">住院号</div>
          <el-input v-model="input" placeholder="请输入住院号"></el-input>
        </div>
        <div class="search-item">
          <span class="search-width">输液状态</span>
          <el-select v-model="statusVal" placeholder="请选择">
            <el-option v-for="item in status" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="search-item">
          <el-button type="primary" plain @click="openAdd">新增</el-button>
        </div>
      </div>
      <div class="icon">
        <span class="el-icon-s-operation item" :class="{ 'active': iconIndex === 1 }" @click="tabClick(1)"></span>
        <span class="el-icon-menu item" :class="{ 'active': iconIndex === 0 }" @click="tabClick(0)"></span>
      </div>
      <div class="label" v-show="iconIndex === 0">
        <div class="label-item" v-for="(item, index) in 10" :key="index" @click="openPop">
          <div class="label-head">
            <div class="label-card yellow">
              <span>01</span>
            </div>
            <span class="name">张然</span>
            <span class="age">66岁</span>
            <span class="sex">♀</span>
          </div>
          <div class="label-line">
            <span>病房:302</span>
          </div>
          <div class="label-line">
            <span>医生:张风超</span>
          </div>
          <div class="label-line">
            <span>总量:100ml</span>
          </div>
          <div class="label-line">
            <span>余量:0ml</span>
          </div>
          <div class="label-line">
            <span>滴速:0d/min</span>
          </div>
          <div class="label-line">
            <span>时间:50:00</span>
          </div>
          <div class="positon">
            <span class="text">呼叫</span>
            <span class="el-icon-message-solid"></span>
          </div>
        </div>
      </div>
      <div class="table" v-show="iconIndex === 1">
        <el-table ref="multipleTable" border :data="tableData" height="100%" stripe>
          <el-table-column type="selection" width="55">
          </el-table-column>
          <el-table-column label="床位"></el-table-column>
          <el-table-column prop="name" label="姓名">
          </el-table-column>
          <el-table-column label="性别">
          </el-table-column>
          <el-table-column label="年龄">
          </el-table-column>
          <el-table-column label="护理等级">
          </el-table-column>
          <el-table-column label="住院号">
          </el-table-column>
          <el-table-column label="联系电话">
          </el-table-column>
          <el-table-column label="住址">
          </el-table-column>
          <el-table-column label="操作">
            <!-- slot-scope="scope" -->
            <template>
              <el-button size="mini" type="primary" @click="openPop">查看详情</el-button>
              <el-button size="mini" type="danger" @click="del">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div class="run-footer">
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="1"
        :page-sizes="[100, 200, 300, 400]" :page-size="100" layout="total, sizes, prev, pager, next, jumper"
        :total="400">
      </el-pagination>
    </div>
    <Add v-if="showUpdate"></Add>
    <Pop v-if="showPop"></Pop>
  </div>
</template>

<script>
import Pop from './pop.vue'
import Add from './update.vue'
export default {
  components: {
    Add,
    Pop
  },
  data () {
    return {
      options: [],
      value: '',
      input: '',
      statusVal: '',
      status: [
        { label: '正常', value: '1' },
        { label: '静止', value: '2' },
        { label: '结束', value: '3' },
        { label: '过快', value: '4' },
        { label: '过慢', value: '5' }
      ],
      iconIndex: 0,
      tableData: [
        {},
        {},
        {}
      ],
      showUpdate: false,
      showPop: false
    }
  },
  methods: {
    handleSizeChange (val) {
      console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      console.log(`当前页: ${val}`)
    },
    tabClick (index) {
      this.iconIndex = index
    },
    openAdd () {
      this.showUpdate = true
    },
    openPop () {
      this.showPop = true
    },
    del () {
      this.$message({
        message: '删除成功',
        type: 'success'
      })
    }
  }
}
</script>

<style lang="less" scoped>
.run {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0 10px;
  background-color: rgba(242, 242, 242, 1);
  box-sizing: border-box;

  .run-box {
    width: 100%;
    height: calc(100% - 80px);
    margin-bottom: 10px;
    background-color: #fff;

    .search {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      width: 100%;
      height: 160px;

      .search-item {
        display: flex;
        flex-direction: row;
        align-items: center;

        .el-input {
          width: auto;
        }

        .el-button {
          margin-left: 40px;
        }
      }

      .search-width {
        width: 100px;
        text-align: center;
        color: #999999;
        font-size: 13px;
      }
    }

    .icon {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      width: 100%;
      height: 60px;
      padding-right: 40px;
      box-sizing: border-box;
      color: #999999;
      font-size: 20px;

      .item {
        margin-right: 20px;
        cursor: pointer;
      }

      .active {
        color: #409EFF;
      }
    }

    .label {
      overflow-y: scroll;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      width: 100%;
      height: calc(100% - 220px);
      padding: 10px;
      box-sizing: border-box;

      &::-webkit-scrollbar {
        width: 0;
      }

      .label-item {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        width: calc(25% - 20px);
        height: 240px;
        margin: 10px;
        padding: 10px;
        background-color: #fff;
        border-radius: 8px;
        border: 1px solid rgba(242, 242, 242, 1);
        box-shadow: 0px 0px 15px rgba(242, 242, 242, 1);
        box-sizing: border-box;
        cursor: pointer;

        .label-head {
          display: flex;
          flex-direction: row;
          align-items: center;
          width: 100%;
          height: 34px;

          .label-card {
            width: 36px;
            height: 34px;
            line-height: 34px;
            text-align: center;
            border-radius: 8px;
            color: #fff;
            font-size: 16px;
          }

          .yellow {
            background-color: rgba(255, 153, 0, 1);
          }

          .green {
            background-color: rgba(0, 153, 0, 1);
          }

          .red {
            background-color: rgba(255, 0, 0, 1);
          }

          .blue {
            background-color: rgba(37, 97, 239, 1);
          }

          .name {
            margin-left: 10px;
            font-size: 18px;
          }

          .age {
            margin-left: 10px;
            font-size: 12px;
          }

          .sex {
            margin-left: 10px;
            font-size: 12px;
          }
        }

        .label-line {
          padding-left: 10px;
          box-sizing: border-box;
          font-size: 14px;
        }

        .positon {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 50px;
          color: #c91b00;
          font-size: 70px;

          .text {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -60%);
            color: #fff;
            font-size: 13px;
            white-space: nowrap;
          }
        }
      }

      .label-item:hover {
        background-color: rgba(242, 247, 251, 1);
      }
    }

    .table {
      width: 100%;
      height: calc(100% - 220px);
      padding: 10px;
      box-sizing: border-box;

      /deep/.el-table__cell {
        text-align: center
      }

      .yellow {
        background-color: rgba(255, 153, 0, 1);
      }

      .green {
        background-color: rgba(0, 153, 0, 1);
      }

      .red {
        background-color: rgba(255, 0, 0, 1);
      }

      .blue {
        background-color: rgba(37, 97, 239, 1);
      }
    }
  }

  .run-footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 70px;
  }
}
</style>
