<template>
  <div class="pop">
    <div class="pop-head">
      <div class="pop-tab">
        <div class="pop-item" :class="{ 'pop-active': tabIndex === index }" v-for="(item, index) in tabs" :key="index" @click="tabClick(index)">
          <span>{{ item }}</span>
        </div>
      </div>
      <el-button type="primary" @click="close">返回</el-button>
    </div>
    <div class="pop-box" v-if="tabIndex === 0">
      <div class="title">
        <span>基本信息</span>
      </div>
      <div class="card">
        <div class="card-item">
          <span class="key">编号</span>
          <span class="value">20200908900</span>
        </div>
        <div class="card-item">
          <span class="key">姓名</span>
          <span class="value">王然</span>
        </div>
        <div class="card-item">
          <span class="key">性别</span>
          <span class="value">女</span>
        </div>
        <div class="card-item">
          <span class="key">身份证号</span>
          <span class="value">371456195908090543</span>
        </div>
        <div class="card-item">
          <span class="key">年龄</span>
          <span class="value">66</span>
        </div>
        <div class="card-item">
          <span class="key">住址</span>
          <span class="value">石家庄市桥西区友谊街238号</span>
        </div>
        <div class="card-item">
          <span class="key">手机号码</span>
          <span class="value">18864308600</span>
        </div>
        <div class="card-item">
          <span class="key">出生日期</span>
          <span class="value">1959-08-09</span>
        </div>
        <div class="card-item">
          <span class="key">民族</span>
          <span class="value">汉族</span>
        </div>
        <div class="card-item">
          <span class="key">文化程度</span>
          <span class="value">本科</span>
        </div>
        <div class="card-item">
          <span class="key">婚姻状况</span>
          <span class="value">已婚</span>
        </div>
        <div class="card-item">
          <span class="key">籍贯</span>
          <span class="value">北京</span>
        </div>
        <div class="card-item">
          <span class="key">费用类型</span>
          <span class="value">医保</span>
        </div>
        <div class="card-item">
          <span class="key">工作单位</span>
          <span class="value">无</span>
        </div>
        <div class="card-item">
          <span class="key">职业</span>
          <span class="value">公务员</span>
        </div>
        <div class="card-item">
          <span class="key">户籍类型</span>
          <span class="value">城镇</span>
        </div>
      </div>

      <div class="title">
        <span>健康信息</span>
      </div>
      <div class="card">
        <div class="card-item">
          <span class="key">身高</span>
          <span class="value">169cm</span>
        </div>
        <div class="card-item">
          <span class="key">体重</span>
          <span class="value">49kg</span>
        </div>
        <div class="card-item">
          <span class="key">血型</span>
          <span class="value">A型</span>
        </div>
        <div class="card-item">
          <span class="key">RH阴性</span>
          <span class="value">否</span>
        </div>
        <div class="card-item">
          <span class="key">过敏史</span>
          <span class="value">无</span>
        </div>
        <div class="card-item">
          <span class="key">既往史</span>
          <span class="value">无</span>
        </div>
        <div class="card-item">
          <span class="key">就诊史</span>
          <span class="value">无</span>
        </div>
        <div class="card-item">
          <span class="key">家族病史</span>
          <span class="value">无</span>
        </div>
        <div class="card-item">
          <span class="key">体温</span>
          <span class="value">37℃</span>
        </div>
        <div class="card-item">
          <span class="key">血糖</span>
          <span class="value">5.9mmol/L</span>
        </div>
        <div class="card-item">
          <span class="key">血压</span>
          <span class="value">120/80mmHg</span>
        </div>
      </div>

      <div class="title">
        <span>住院信息</span>
      </div>
      <div class="card">
        <div class="card-item">
          <span class="key">住院号</span>
          <span class="value">00916279</span>
        </div>
        <div class="card-item">
          <span class="key">床位</span>
          <span class="value">01</span>
        </div>
        <div class="card-item">
          <span class="key">病房</span>
          <span class="value">302</span>
        </div>
        <div class="card-item">
          <span class="key">入院时间</span>
          <span class="value">2024-05-23</span>
        </div>
        <div class="card-item">
          <span class="key">入住科室</span>
          <span class="value">肝胆胰腺外科</span>
        </div>
        <div class="card-item">
          <span class="key">诊断</span>
          <span class="value">脑血栓后遗症 高血压 右眼失明</span>
        </div>
        <div class="card-item">
          <span class="key">主治医生</span>
          <span class="value">梅智鑫</span>
        </div>
        <div class="card-item">
          <span class="key">护理级别</span>
          <span class="value">一级护理</span>
        </div>
        <div class="card-item">
          <span class="key">护士</span>
          <span class="value">张风超</span>
        </div>
        <div class="card-item">
          <span class="key">护理员</span>
          <span class="value">张超</span>
        </div>
        <div class="card-item">
          <span class="key">医保费别</span>
          <span class="value">现金</span>
        </div>
        <div class="card-item"></div>
        <div class="card-item">
          <span class="key">预交款</span>
          <span class="value">5000元</span>
        </div>
        <div class="card-item">
          <span class="key">费用合计</span>
          <span class="value">6300元</span>
        </div>
        <div class="card-item">
          <span class="key">自付费用</span>
          <span class="value">3000元</span>
        </div>
      </div>
    </div>
    <div class="pop-box" v-else-if="tabIndex === 1">
      <div class="table-title">
        <span>医嘱列表</span>
      </div>
      <el-table ref="multipleTable" border :data="tableData" height="200" stripe>
        <el-table-column label="组号"></el-table-column>
        <el-table-column prop="name" label="类别">
        </el-table-column>
        <el-table-column label="名称">
        </el-table-column>
        <el-table-column label="规格">
        </el-table-column>
        <el-table-column label="剂量">
        </el-table-column>
      </el-table>
      <div class="table-title">
        <span>执行明细</span>
      </div>
      <el-table ref="multipleTable" border :data="tableData" height="200" stripe>
        <el-table-column label="执行时间状态"></el-table-column>
        <el-table-column prop="name" label="类别">
        </el-table-column>
        <el-table-column label="名称">
        </el-table-column>
        <el-table-column label="规格">
        </el-table-column>
        <el-table-column label="剂量">
        </el-table-column>
      </el-table>
    </div>
    <div class="pop-box pop-box1" v-else-if="tabIndex === 2">
      <Tab3></Tab3>
    </div>
    <div class="pop-box pop-box1" v-else-if="tabIndex === 3">
      <Tab4></Tab4>
    </div>
    <div class="pop-box pop-box1" v-else-if="tabIndex === 4">
      <Tab5></Tab5>
    </div>
    <div class="pop-footer" v-if="tabIndex === 0">
      <el-button type="primary" @click="open">编辑信息</el-button>
    </div>
    <!-- 编辑信息 -->
    <Update v-if="showUpdate"></Update>
    <!-- 输液记录 -->
    <Add v-if="showAdd"></Add>
  </div>
</template>

<script>
import Update from './update.vue'
import Add from './add.vue'
import Tab3 from './tab3/tab3.vue'
import Tab4 from './tab4/tab4.vue'
import Tab5 from './tab5/tab5.vue'
export default {
  components: {
    Update,
    Add,
    Tab3,
    Tab4,
    Tab5
  },
  data () {
    return {
      tabIndex: 0,
      tabs: Object.freeze(['编辑信息', '医嘱信息', '体温单', '护理文书', '护理评分']),
      tableData: [
        {},
        {},
        {}
      ],
      showUpdate: false,
      showAdd: false
    }
  },
  computed: {
    btnType () {
      return this.types[this.tabIndex]
    }
  },
  methods: {
    tabClick (index) {
      this.tabIndex = index
    },
    open () {
      const arr = ['showUpdate', 'showAdd']
      this.$data[arr[this.tabIndex]] = true
    },
    close () {
      this.$parent.showPop = false
    }
  }
}
</script>

<style lang="less" scoped>
.pop {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 88;
  width: 100%;
  height: 100%;
  padding: 10px;
  background-color: #fff;
  box-sizing: border-box;

  .pop-head {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 48px;
    padding-right: 40px;
    box-sizing: border-box;

    .pop-tab {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 48px;

      .pop-item {
        height: 48px;
        line-height: 48px;
        padding: 0 40px;
        background-color: rgba(242, 247, 251, 0.5);
        color: #1877F2;
        font-size: 16px;
        cursor: pointer;
      }

      .pop-active {
        color: #fff;
        background-color: rgba(24, 119, 242, 1);
      }
    }
  }

  .pop-box {
    overflow-y: scroll;
    width: 100%;
    height: calc(100% - 120px);
    margin-top: 10px;

    &::-webkit-scrollbar {
      width: 0;
    }

    .title {
      width: 100%;
      height: 40px;
      line-height: 40px;
      padding-left: 20px;
      box-sizing: border-box;
      font-size: 18px;
    }

    .card {
      display: flex;
      flex-wrap: wrap;
      width: calc(100% - 40px);
      margin-left: 20px;
      padding: 20px 10px;
      border-bottom: 1px solid rgba(242, 247, 251, 1);
      box-sizing: border-box;

      .card-item {
        display: flex;
        align-items: center;
        width: calc(25% - 10px);
        margin: 10px 5px;
        font-size: 16px;

        .key {
          width: 90px;
          text-align: right;
          color: #999999;
        }

        .value {
          width: calc(100% - 100px);
          margin-left: 10px;
          word-wrap: break-word;
        }
      }
    }

    .card-box {
      padding: 10px;
      background-color: rgba(242, 247, 251, 1);
      box-sizing: border-box;

      .card-title {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        height: 50px;
        line-height: 50px;
        font-size: 16px;

        .status {
          height: 24px;
          line-height: 24px;
          margin-left: 10px;
          padding: 0 10px;
          border-radius: 10px;
          color: #fff;
          font-size: 14px;
        }

        .red {
          background-color: rgba(250, 116, 107, 1);
        }

        .blue {
          background-color: rgba(37, 97, 239, 1);
        }
      }

      .card-content {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;

        .card-content-item {
          display: flex;
          flex-direction: row;
          align-items: center;
          width: calc(33.3% - 10px);
          margin: 10px 5px;
          font-size: 16px;

          .key {
            color: #999999;
          }

          .value {
            width: calc(100% - 100px);
            margin-left: 10px;
            word-wrap: break-word;
          }
        }
      }
    }

    .table-title {
      width: 100%;
      height: 60px;
      line-height: 60px;
      font-size: 16px;
    }
  }

  .pop-box1 {
    height: calc(100% - 58px);
  }

  .pop-footer {
    display: flex;
    align-items: center;
    width: 100%;
    height: 62px;
  }
}
</style>
