<template>
  <div class="estimate">
    <div class="estimate-head">
      <div class="estimate-item" :class="{ 'estimate-active': index === tabIndex }" v-for="(item, index) in tabs" :key="index" @click="tabClick(index)">
        <span>{{ item }}</span>
      </div>
    </div>
    <div class="estimate-box">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data () {
    return {
      tabs: Object.freeze(['入院评估', '每日护理评估单', '健康教育记录单', '每日评估']),
      tabIndex: 0,
      urls: Object.freeze(['/tab1', '/tab2', '/tab3', '/tab4']),
      input: '',
      tableData: [
        {},
        {},
        {}
      ]
    }
  },
  methods: {
    tabClick (index) {
      this.tabIndex = index
      this.$router.push('/smartbeds/nursdocument/estimate' + this.urls[index])
    }
  }
}
</script>

<style lang="less" scoped>
.estimate {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 10px;
  background-color: #fff;
  box-sizing: border-box;

  .estimate-head {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 48px;
    padding-right: 40px;
    box-sizing: border-box;

    .estimate-item {
      height: 48px;
      line-height: 48px;
      padding: 0 40px;
      background-color: rgba(242, 247, 251, 0.5);
      color: #1877F2;
      font-size: 16px;
      cursor: pointer;
    }

    .estimate-active {
      color: #fff;
      background-color: rgba(24, 119, 242, 1);
    }
  }

  .estimate-box {
    display: flex;
    width: 100%;
    height: calc(100% - 58px);
    margin-top: 10px;

    .estimate-left {
      width: 380px;
      height: 100%;
      border-radius: 8px;
      border: 1px solid rgba(242, 242, 242, 1);
      box-shadow: 0px 0px 15px rgba(242, 242, 242, 1);
      box-sizing: border-box;

      .estimate-search {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        width: 100%;
        height: 60px;

        .item-search {
          display: flex;
          flex-direction: row;
          align-items: center;
          width: 45%;
          height: 50px;
        }

        .span {
          margin-right: 10px;
          white-space: nowrap;
          font-size: 16px;
        }
      }
    }

    .estimate-right {
      width: calc(100% - 400px);
      height: 100%;
      margin-left: 20px;
      border-radius: 8px;
      border: 1px solid rgba(242, 242, 242, 1);
      box-shadow: 0px 0px 15px rgba(242, 242, 242, 1);
      box-sizing: border-box;

      .estimate-print {
        display: flex;
        flex-direction: row-reverse;
        width: 100%;
        height: 60px;
        padding-right: 80px;
        box-sizing: border-box;
        font-size: 16px;

        span {
          cursor: pointer;
        }

        .span {
          margin-right: 20px;
          font-size: 24px;
        }
      }
    }

    .estimate-title {
      width: 100%;
      height: 50px;
      line-height: 50px;
      text-align: center;
      font-size: 16px;
    }

    .estimate-table {
      width: 100%;
      height: calc(100% - 110px);

      /deep/.el-table__cell {
        text-align: center;
      }
    }
  }
}
</style>
