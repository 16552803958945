<template>
  <div class="pop">
    <div class="pop-content">
      <div class="pop-head">
        <span>病人评估</span>
        <span class="pop-close el-icon-close" @click="close"></span>
      </div>
      <div class="pop-box">
        <div class="pop-top">
          <div class="pop-item">
            <span class="span">评估时间</span>
            <el-date-picker v-model="value1" type="date" placeholder="请选择日期">
            </el-date-picker>
          </div>
          <div class="pop-item">
            <span class="span">评估人</span>
            <el-input v-model="input" placeholder="请输入"></el-input>
          </div>
        </div>
        <div class="pop-scroll">
          <el-checkbox-group v-model="checkList">
            <el-checkbox label="1">
              <span class="text">照顾者</span>
              <el-select v-model="value" placeholder="请选择">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-checkbox>
            <el-checkbox label="2">
              <span class="text">Thranslation Needed  需翻译</span>
              <el-select v-model="value" placeholder="请选择">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-checkbox>
            <el-checkbox label="3">
              <span class="text">Psychological  心理</span>
              <el-select v-model="value" placeholder="请选择">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-checkbox>
            <el-checkbox label="4">
              <span class="text">Literacy 识字</span>
              <el-select v-model="value" placeholder="请选择">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-checkbox>
            <el-checkbox label="5">
              <span class="text">Memory Loss失忆</span>
              <el-select v-model="value" placeholder="请选择">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-checkbox>
            <el-checkbox label="6">
              <span class="text">Religious/Cultural Practices宗教/文化习俗</span>
              <el-select v-model="value" placeholder="请选择">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-checkbox>
            <el-checkbox label="7">
              <span class="text">Hearing 听力</span>
              <el-select v-model="value" placeholder="请选择">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-checkbox>
            <el-checkbox label="8">
              <span class="text">Vision  视力</span>
              <span class="text">左眼</span>
              <el-select v-model="value" placeholder="请选择">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
              <span class="text text1">右眼</span>
              <el-select v-model="value" placeholder="请选择">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-checkbox>
            <el-checkbox label="9">
              <span class="text">Speeck 语言表达</span>
              <el-select v-model="value" placeholder="请选择">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-checkbox>
            <el-checkbox label="10">
              <span class="text">Caregiver not available  无照顾者</span>
              <el-select v-model="value" placeholder="请选择">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-checkbox>
            <el-checkbox label="11">
              <span class="text">Other    其它</span>
              <el-select v-model="value" placeholder="请选择">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-checkbox>
          </el-checkbox-group>
        </div>
      </div>
      <div class="pop-footer">
        <el-button type="primary">保存</el-button>
        <el-button type="primary" plain>取消</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data () {
    return {
      input: '',
      options: [],
      value: '',
      value1: '',
      checkList: []
    }
  },
  methods: {
    close () {
      this.$parent.showAdd = false
    }
  }
}
</script>

<style lang="less" scoped>
.pop {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 88;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 10px;
  background-color: transparent;
  box-sizing: border-box;

  .pop-content {
    width: 60%;
    height: 100%;
    background-color: #fff;
    border: 1px solid rgba(228, 228, 228, 1);
    box-sizing: border-box;

    .pop-head {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 50px;
      background-color: rgba(242, 242, 242, 1);
      padding: 0 20px;
      box-sizing: border-box;
      font-size: 16px;

      .pop-close {
        font-size: 20px;
        cursor: pointer;
      }
    }

    .pop-box {
      width: 100%;
      height: calc(100% - 100px);
      padding-left: 40px;
      box-sizing: border-box;

      .pop-top {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        height: 60px;
        color: #999999;
        font-size: 14px;

        .pop-item {
          display: flex;
          flex-direction: row;
          align-items: center;
          width: 50%;
          height: 100%;

          .span {
            margin-right: 10px;
          }

          .el-input {
            width: 200px;
          }
        }
      }

      .pop-scroll {
        overflow-y: scroll;
        width: 100%;
        height: calc(100% - 60px);

        &::-webkit-scrollbar {
          width: 0;
        }

        .el-checkbox-group {
          display: flex;
          flex-direction: column;

          .el-checkbox {
            margin: 10px 0;
          }
        }

        .text {
          margin-right: 10px;
          font-size: 16px;
        }

        .text1 {
          margin-left: 10px;
        }
      }
    }

    .pop-footer {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-evenly;
      width: 100%;
      height: 50px;
    }
  }
}
</style>
