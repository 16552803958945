<template>
  <div class="board">
    <div class="board-head">
      <div class="total">
        <span>33</span>
        <span class="size">总人数</span>
      </div>
      <div class="title">
        <span>状</span>
        <span>态</span>
        <span>记</span>
        <span>录</span>
      </div>
      <div class="info">
        <div class="info-item">
          <span>病重： 08  07</span>
        </div>
        <div class="info-item">
          <span>压疮： 08  07</span>
        </div>
        <div class="info-item">
          <span>引流管： 08  07</span>
        </div>
        <div class="info-item">
          <span>尿量： 08  07</span>
        </div>
        <div class="info-item">
          <span>出入量： 08  07</span>
        </div>
        <div class="info-item">
          <span>跌倒： 08  07</span>
        </div>
      </div>
      <div class="title">
        <span>手</span>
        <span>术</span>
        <span>病</span>
        <span>人</span>
      </div>
      <div class="info1">
        <div class="info-item">
          <span>术中： 08  07</span>
        </div>
        <div class="info-item">
          <span>术后： 08  07</span>
        </div>
      </div>
      <div class="title">
        <span>护</span>
        <span>理</span>
        <span>级</span>
        <span>别</span>
      </div>
      <div class="info2">
        <div class="info-lable">
          <span>特级护理：5</span>
        </div>
        <div class="info-lable">
          <span>二级护理：5</span>
        </div>
        <div class="info-lable">
          <span>一级护理：5</span>
        </div>
        <div class="info-lable">
          <span>三级护理：5</span>
        </div>
      </div>
    </div>
    <div class="board-box">
      <div class="search">
        <div class="search-item">
          <span class="search-width">机构</span>
          <el-select v-model="value" placeholder="请选择">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
          <el-select v-model="value" placeholder="请选择下属机构">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="search-item">
          <span class="search-width">病房</span>
          <el-select v-model="value" placeholder="请选择">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="search-item">
          <span class="search-width">姓名</span>
          <el-input v-model="input" placeholder="请输入姓名"></el-input>
        </div>
        <div class="search-item">
          <span class="search-width">身份证号</span>
          <el-input v-model="input" placeholder="请输入身份证号"></el-input>
        </div>
        <div class="search-item">
          <div class="search-width">住院号</div>
          <el-input v-model="input" placeholder="请输入住院号"></el-input>
        </div>
        <div class="search-item">
          <span class="search-width">住院状态</span>
          <el-select v-model="statusVal" placeholder="请选择">
            <el-option
              v-for="item in status"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="label">
        <div class="label-item" v-for="(item, index) in 10" :key="index" @click="openPop">
          <div class="label-head">
            <div class="label-card yellow">
              <span>01</span>
            </div>
            <span class="name">张然</span>
            <span class="age">66岁</span>
            <span class="sex">♀</span>
          </div>
          <div class="label-line">
            <span>病房:302</span>
          </div>
          <div class="label-line">
            <span>医生:张风超</span>
          </div>
          <div class="label-line">
            <span>总量:100ml</span>
          </div>
          <div class="label-line">
            <span>余量:0ml</span>
          </div>
          <div class="label-line">
            <span>滴速:0d/min</span>
          </div>
          <div class="label-line">
            <span>时间:50:00</span>
          </div>
          <div class="positon">
            <span class="text">呼叫</span>
            <span class="el-icon-message-solid"></span>
          </div>
        </div>
      </div>
    </div>
    <div class="board-footer">
      <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="1"
      :page-sizes="[100, 200, 300, 400]"
      :page-size="100"
      layout="total, sizes, prev, pager, next, jumper"
      :total="400">
    </el-pagination>
    </div>
    <Pop v-if="showPop"></Pop>
  </div>
</template>

<script>
import Pop from './pop.vue'
export default {
  components: {
    Pop
  },
  data () {
    return {
      options: [],
      value: '',
      input: '',
      statusVal: '',
      status: [
        { label: '新入院', value: '1' },
        { label: '住院中', value: '2' },
        { label: '已出院', value: '3' }
      ],
      showPop: false
    }
  },
  methods: {
    handleSizeChange (val) {
      console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      console.log(`当前页: ${val}`)
    },
    openPop () {
      this.showPop = true
    }
  }
}
</script>

<style lang="less" scoped>
  .board {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0 10px;
    background-color: rgba(242, 242, 242, 1);
    box-sizing: border-box;

    .board-head {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      height: 90px;
      background-color: #fff;

      .total {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 90px;
        height: 100%;
        font-size: 18px;

        .size {
          font-size: 14px;
        }
      }

      .title {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 20px;
        height: 100%;
        background-color: #6791f4;
        color: #fff;
        font-size: 13px;
      }

      .info {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        flex: 3;
        height: 100%;
        padding: 0 5px;
        box-sizing: border-box;
      }

      .info1 {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        flex: 2;
        width: 190px;
        height: 100%;
        padding: 0 5px;
        box-sizing: border-box;

        .info-item {
          width: 100%;
        }
      }

      .info2 {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        flex: 1;
        width: calc(100% - 810px);
        padding: 0 5px;
        box-sizing: border-box;

        .info-lable {
          min-width: 103px;
          width: calc(50% - 10px);
          margin: 5px;
          color: #fff;
          font-size: 14px;
        }

        .info-lable:nth-child(1) {
          background-color: rgba(255, 0, 0, 1);
        }

        .info-lable:nth-child(2) {
          background-color: rgba(37, 97, 239, 1);
        }

        .info-lable:nth-child(3) {
          background-color: rgba(255, 153, 0, 1);
        }

        .info-lable:nth-child(4) {
          background-color: rgba(0, 153, 0, 1);
        }
      }

      .info-item {
        width: 33.3%;
        height: 45px;
        line-height: 45px;
        font-size: 14px;
      }
    }

    .board-box {
      width: 100%;
      height: calc(100% - 180px);
      margin: 10px 0;
      background-color: #fff;

      .search {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
        height: 160px;

        .search-item {
          display: flex;
          flex-direction: row;
          align-items: center;

          .el-input {
            width: auto;
          }
        }

        .search-width {
          width: 100px;
          text-align: center;
          color: #999999;
          font-size: 13px;
        }
      }

      .label {
        overflow-y: scroll;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
        height: calc(100% - 160px);
        padding: 10px;
        box-sizing: border-box;

        &::-webkit-scrollbar {
          width: 0;
        }

        .label-item {
          position: relative;
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          width: calc(25% - 30px);
          height: 240px;
          margin: 10px;
          padding: 10px;
          background-color: #fff;
          border-radius: 8px;
          border: 1px solid rgba(242, 242, 242, 1);
          box-shadow: 0px 0px 15px rgba(242, 242, 242, 1);
          box-sizing: border-box;
          cursor: pointer;

          .label-head {
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 100%;
            height: 34px;

            .label-card {
              width: 36px;
              height: 34px;
              line-height: 34px;
              text-align: center;
              border-radius: 8px;
              color: #fff;
              font-size: 16px;
            }

            .yellow {
              background-color: rgba(255, 153, 0, 1);
            }

            .green {
              background-color: rgba(0, 153, 0, 1);
            }

            .red {
              background-color: rgba(255, 0, 0, 1);
            }

            .blue {
              background-color: rgba(37, 97, 239, 1);
            }

            .name {
              margin-left: 10px;
              font-size: 18px;
            }

            .age {
              margin-left: 10px;
              font-size: 12px;
            }

            .sex {
              margin-left: 10px;
              font-size: 12px;
            }
          }

          .label-line {
            padding-left: 10px;
            box-sizing: border-box;
            font-size: 14px;
          }

          .positon {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 50px;
            color: #c91b00;
            font-size: 70px;

            .text {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -60%);
              color: #fff;
              font-size: 13px;
              white-space: nowrap;
            }
          }
        }

        .label-item:hover {
          background-color: rgba(242, 247, 251, 1);
        }
      }
    }

    .board-footer {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 70px;
    }
  }
</style>
