<template>
  <div class="tab">
    <div class="tab-right">
      <!-- <div class="tab-position">
        <el-button type="primary" @click="showAdd = true">新建</el-button>
        <el-button type="primary">打印</el-button>
      </div> -->
      <div class="tab-title">
        <span>选择患者</span>
      </div>
      <div class="tab-table">
        <el-table :data="tableData" stripe border height="100%">
          <el-table-column prop="name" label="评估时间">
          </el-table-column>
          <el-table-column prop="address" label="照顾者">
          </el-table-column>
          <el-table-column prop="address" label="需翻译">
          </el-table-column>
          <el-table-column prop="address" label="心理">
          </el-table-column>
          <el-table-column prop="address" label="识字">
          </el-table-column>
          <el-table-column prop="address" label="失忆">
          </el-table-column>
          <el-table-column prop="address" label="宗教文化习俗">
          </el-table-column>
          <el-table-column prop="address" label="听力">
          </el-table-column>
          <el-table-column prop="address" label="视力">
          </el-table-column>
          <el-table-column prop="address" label="语言表达">
          </el-table-column>
          <el-table-column prop="address" label="无照顾者">
          </el-table-column>
          <el-table-column prop="address" label="其他">
          </el-table-column>
        </el-table>
      </div>
      <div class="tab-title">
        <span>健康教育</span>
      </div>
      <div class="tab-table">
        <el-table :data="tableData" stripe border height="100%">
          <el-table-column prop="name" label="教育时间">
          </el-table-column>
          <el-table-column prop="address" label="教育对象">
          </el-table-column>
          <el-table-column prop="address" label="教育时机">
          </el-table-column>
          <el-table-column prop="address" label="健康教育个性化需求">
          </el-table-column>
          <el-table-column prop="address" label="教育方法">
          </el-table-column>
          <el-table-column prop="address" label="效果评价">
          </el-table-column>
          <el-table-column prop="address" label="备注">
          </el-table-column>
        </el-table>
      </div>
    </div>
    <Add v-if="showAdd"></Add>
  </div>
</template>

<script>
import Add from './add.vue'
export default {
  components: {
    Add
  },
  data () {
    return {
      input: '',
      value: '',
      options: [],
      radio: '',
      value1: '',
      textarea: '',
      tableData: [
        {},
        {},
        {}
      ],
      showAdd: false
    }
  }
}
</script>

<style lang="less" scoped>
.tab {
  display: flex;
  width: 100%;
  height: 100%;

  .tab-right {
    position: relative;
    width: 100%;
    height: 100%;
    box-sizing: border-box;

    .tab-title {
      width: 100%;
      height: 70px;
      line-height: 70px;
      padding-left: 20px;
      border-bottom: 1px solid rgba(0, 0, 0, .1);
      box-sizing: border-box;
      font-size: 20px;
    }

    .tab-position {
      position: absolute;
      right: 10px;
      top: 10px;
    }

    .tab-table {
      width: 100%;
      height: calc(50% - 80px);
      margin-top: 10px;

      /deep/.el-table__cell {
        text-align: center;
      }
    }
  }

}
</style>
