<template>
  <div class="record">
    <div class="record-head">
      <span>出入量记录单</span>
    </div>
    <div class="record-box">
      <div class="record-left">
        <div class="record-title">
          <span>选择患者</span>
        </div>
        <div class="record-search">
          <div class="item-search">
            <span class="span">床位</span>
            <el-input v-model="input" placeholder="请输入床位"></el-input>
          </div>
          <div class="item-search">
            <span class="span">姓名</span>
            <el-input v-model="input" placeholder="请输入姓名"></el-input>
          </div>
        </div>
        <div class="record-table">
          <el-table :data="tableData" stripe border height="100%">
            <el-table-column type="selection" width="55">
            </el-table-column>
            <el-table-column prop="name" label="床位">
            </el-table-column>
            <el-table-column prop="address" label="姓名">
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="record-right">
        <div class="record-print">
          <div class="print-item print-item1">
            <span>选择日期</span>
            <el-date-picker
              v-model="value1"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期">
            </el-date-picker>
          </div>
          <el-button type="primary">打印</el-button>
          <el-button type="primary">录入</el-button>
          <el-button type="primary">写入体温单</el-button>
        </div>
        <div class="table-title">
          <span>医嘱执行明细</span>
        </div>
        <div class="record-table">
          <el-table :data="tableData" stripe border height="100%">
            <el-table-column prop="name" label="时间">
            </el-table-column>
            <el-table-column prop="address" label="入量名称">
            </el-table-column>
            <el-table-column prop="address" label="入量方式">
            </el-table-column>
            <el-table-column prop="address" label="途径">
            </el-table-column>
            <el-table-column prop="address" label="量(ml)">
            </el-table-column>
            <el-table-column prop="address" label="出量名称">
            </el-table-column>
            <el-table-column prop="address" label="出量方式">
            </el-table-column>
            <el-table-column prop="address" label="性质">
            </el-table-column>
            <el-table-column prop="address" label="量(ml)">
            </el-table-column>
            <el-table-column prop="address" label="签名">
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data () {
    return {
      input: '',
      value: '',
      options: [],
      radio: '',
      value1: '',
      tableData: [
        {},
        {},
        {}
      ]
    }
  },
  methods: {}
}
</script>

<style lang="less" scoped>
.record {
  width: 100%;
  height: 100%;
  padding: 10px;
  padding-top: 0;
  background-color: #fff;
  box-sizing: border-box;

  .record-head {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 48px;
    padding-right: 40px;
    border-bottom: 1px solid rgba(0, 0, 0, .1);
    box-sizing: border-box;
    font-size: 20px;
  }

  .record-box {
    display: flex;
    width: 100%;
    height: calc(100% - 58px);
    margin-top: 10px;

    .record-left {
      width: 380px;
      height: 100%;
      border-radius: 8px;
      border: 1px solid rgba(242, 242, 242, 1);
      box-shadow: 0px 0px 15px rgba(242, 242, 242, 1);
      box-sizing: border-box;

      .record-search {
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-wrap: wrap;
        width: 100%;
        height: 60px;

        .item-search {
          display: flex;
          flex-direction: row;
          align-items: center;
          width: calc(50% - 20px);
          height: 50px;
          margin: 0 10px;
        }

        .span {
          margin-right: 10px;
          white-space: nowrap;
          font-size: 16px;
        }
      }
    }

    .record-right {
      width: calc(100% - 400px);
      height: 100%;
      margin-left: 20px;
      padding: 10px;
      border-radius: 8px;
      border: 1px solid rgba(242, 242, 242, 1);
      box-shadow: 0px 0px 15px rgba(242, 242, 242, 1);
      box-sizing: border-box;

      .record-print {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        width: 100%;
        height: 60px;
        color: #999;
        font-size: 16px;

        .print-item {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-right: 20px;
          font-size: 16px;

          .el-select {
            width: 120px;
            margin-left: 10px;
          }

          >span {
            white-space: nowrap;
          }

          .el-date-editor {
            margin-left: 10px;
          }
        }
      }

      .table-title {
        width: 100%;
        height: 40px;
        line-height: 40px;
        font-size: 18px;
      }

      .record-table {
        width: 100%;
        height: calc(100% - 100px);
      }
    }

    .record-title {
      width: 100%;
      height: 50px;
      line-height: 50px;
      text-align: center;
      font-size: 16px;
    }

    .record-table {
      width: 100%;
      height: calc(100% - 170px);

      /deep/.el-table__cell {
        text-align: center;
      }
    }
  }
}
</style>
