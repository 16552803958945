<template>
  <div class="son">
    <el-table :data="tableData" stripe border height="100%">
      <el-table-column prop="name" label="时间">
      </el-table-column>
      <el-table-column prop="address" label="入量名称">
      </el-table-column>
      <el-table-column prop="name" label="入量方式">
      </el-table-column>
      <el-table-column prop="address" label="途径">
      </el-table-column>
      <el-table-column prop="address" label="量(ml)">
      </el-table-column>
      <el-table-column prop="address" label="出量名称">
      </el-table-column>
      <el-table-column prop="address" label="出量方式">
      </el-table-column>
      <el-table-column prop="address" label="性质">
      </el-table-column>
      <el-table-column prop="address" label="量(ml)">
      </el-table-column>
      <el-table-column prop="address" label="签名">
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  data () {
    return {
      tableData: [
        {},
        {},
        {}
      ]
    }
  }
}
</script>

<style lang="less" scoped>
.son {
  width: 100%;
  height: 100%;

  /deep/.el-table__cell {
    text-align: center;
  }
}
</style>
