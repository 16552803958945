<template>
  <div class="tab">
    <div class="tab-search">
      <div class="tab-item">
        <span class="text">第</span>
        <el-select v-model="value" placeholder="请选择">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
        <span class="text">周</span>
      </div>
      <div class="tab-item">
        <span class="text">系数</span>
        <el-select v-model="value" placeholder="请选择">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="tab-item">
        <el-radio-group v-model="radio">
          <el-radio label="1">合适高度</el-radio>
          <el-radio label="2">实际大小</el-radio>
        </el-radio-group>
      </div>
      <div class="tab-item" @click="showEnter = true">
        <span class="icon el-icon-document"></span>
        <span class="text">系数</span>
      </div>
      <div class="tab-item">
        <span class="icon el-icon-printer"></span>
        <span class="text">打印</span>
      </div>
    </div>
    <div class="tab-info">
      <span>床位：01</span>
      <span>姓名：张三</span>
      <span>科室：肝胆外科</span>
      <span>入院时间：2024-05-06</span>
      <span>住院号：0091679</span>
    </div>
    <div class="tab-chart"></div>
    <Enter v-if="showEnter"></Enter>
  </div>
</template>

<script>
import Enter from './enter.vue'
export default {
  components: {
    Enter
  },
  data () {
    return {
      options: [],
      radio: '',
      value: '',
      showEnter: false
    }
  }
}
</script>

<style lang="less" scoped>
.tab {
  width: 100%;
  height: 100%;

  .tab-search {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    height: 60px;

    .tab-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: 0 10px;
      cursor: pointer;
      color: #999;
      font-size: 16px;

      .text {
        margin: 0 5px;
      }

      .icon {
        margin-left: 10px;
        font-size: 24px;
      }
    }
  }

  .tab-info {
    display: flex;
    align-items: center;
    width: 100%;
    height: 40px;
    font-size: 16px;

    span {
      margin:  0 20px;
    }
  }

  .tab-chart {
    width: 100%;
    height: calc(100% - 100px);
    background-color: #eaf7fe;
  }
}
</style>
