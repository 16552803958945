<template>
  <div class="add">
    <div class="add-head">
      <span>新增文书模板</span>
      <div class="add-right">
        <el-button type="primary">文书预览</el-button>
        <el-button type="primary" @click="close">返回</el-button>
      </div>
    </div>
    <div class="add-search">
      <div class="add-item">
        <span class="span">模板名称</span>
        <el-input v-model="input" placeholder="请输入内容"></el-input>
      </div>
      <div class="add-item">
        <span class="span">显示名</span>
        <el-input v-model="input" placeholder="请输入内容"></el-input>
      </div>
      <div class="add-item">
        <span class="span">报表类型</span>
        <el-select v-model="value" placeholder="请选择">
          <el-option v-for="item in bbs" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="add-item">
        <span class="span">全院模板</span>
        <el-input v-model="input" placeholder="请输入内容"></el-input>
      </div>
      <div class="add-item">
        <el-checkbox-group v-model="checkList">
          <el-checkbox label="1">
            <span class="select">全院模板</span>
          </el-checkbox>
          <el-checkbox label="2">
            <span class="select">评分模板</span>
          </el-checkbox>
          <el-checkbox label="3">
            <span class="select">需腕带扫码</span>
          </el-checkbox>
        </el-checkbox-group>
      </div>
      <div class="add-item">
        <span class="span">关联科室</span>
        <el-select v-model="value" placeholder="请选择">
          <el-option v-for="item in glkss" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
    </div>
    <div class="add-box">
      <div class="add-left">
        <div class="add-title">
          <span>节点列表</span>
          <el-checkbox v-model="checked">显示节点编号</el-checkbox>
        </div>
        <div class="add-scroll">
          <div class="tree">
            <span class="el-icon-remove-outline icon" @click="show = !show"></span>
            <span class="span">护理记录单</span>
            <span class="el-icon-edit icon"></span>
            <span class="el-icon-plus icon" @click="add"></span>
          </div>
          <div class="tree-children" v-for="(item, index) in treeData" :key="item.id" v-show="show">
            <div class="tree-text">
              <el-input v-model="item.label" :ref="'ref' + index" :disabled="!item.select" placeholder=""
                @blur="blur(index)"></el-input>
            </div>
            <div class="tree-icon" @click="update(item, index)">
              <span class="el-icon-edit"></span>
            </div>
            <div class="tree-icon" @click="del(item.id)">
              <span class="el-icon-delete"></span>
            </div>
          </div>
          <div class="tree">
            <span class="el-icon-remove-outline icon" @click="show1 = !show1"></span>
            <span class="span">入院护理评估单</span>
            <span class="el-icon-edit icon"></span>
            <span class="el-icon-plus icon" @click="add"></span>
          </div>
          <div class="tree-children" v-for="(item, index) in treeData1" :key="item.id + 'a'" v-show="show1">
            <div class="tree-text">
              <el-input v-model="item.label" :ref="'ref' + index" :disabled="!item.select" placeholder=""
                @blur="blur(index)"></el-input>
            </div>
            <div class="tree-icon" @click="update(item, index)">
              <span class="el-icon-edit"></span>
            </div>
            <div class="tree-icon" @click="del(item.id)">
              <span class="el-icon-delete"></span>
            </div>
          </div>
          <div class="tree">
            <span class="el-icon-remove-outline icon" @click="show2 = !show2"></span>
            <span class="span">Braden评分</span>
            <span class="el-icon-edit icon"></span>
            <span class="el-icon-plus icon" @click="add"></span>
          </div>
          <div class="tree-children" v-for="(item, index) in treeData2" :key="item.id + 'b'" v-show="show2">
            <div class="tree-text">
              <el-input v-model="item.label" :ref="'ref' + index" :disabled="!item.select" placeholder=""
                @blur="blur(index)"></el-input>
            </div>
            <div class="tree-icon" @click="update(item, index)">
              <span class="el-icon-edit"></span>
            </div>
            <div class="tree-icon" @click="del(item.id)">
              <span class="el-icon-delete"></span>
            </div>
          </div>
        </div>
        <div class="add-button">
          <el-button type="primary">增加根</el-button>
        </div>
      </div>
      <div class="add-right">
        <div class="add-title">
          <span>公共属性</span>
        </div>
        <div class="add-scroll">
          <div class="add-scroll-title">
            <span>设计</span>
          </div>
          <div class="add-scroll-box">
            <div class="add-scroll-item">
              <span class="span">名称</span>
              <el-input v-model="input" placeholder="请输入"></el-input>
            </div>
            <div class="add-scroll-item">
              <span class="span">分值</span>
              <el-input v-model="input" placeholder="请输入"></el-input>
            </div>
            <div class="add-scroll-item">
              <span class="span">类型</span>
              <el-select v-model="value" placeholder="请选择">
                <el-option v-for="item in lxs" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </div>
            <div class="add-scroll-item">
              <span class="span">初始状态</span>
              <el-select v-model="value" placeholder="请选择">
                <el-option v-for="item in lxs" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </div>
            <div class="add-scroll-item">
              <span class="span">数据类型</span>
              <el-select v-model="value" placeholder="请选择">
                <el-option v-for="item in sjlxs" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="add-scroll-title">
            <span>数据</span>
          </div>
          <div class="add-scroll-box">
            <div class="add-scroll-item">
              <span class="span">前缀</span>
              <el-input v-model="input" placeholder="请输入"></el-input>
            </div>
            <div class="add-scroll-item">
              <span class="span">后缀</span>
              <el-input v-model="input" placeholder="请输入"></el-input>
            </div>
            <div class="add-scroll-item">
              <span class="span">枚举值</span>
              <el-input v-model="input" placeholder="请输入"></el-input>
            </div>
            <div class="add-scroll-item">
              <span class="span">数据关联</span>
              <el-select v-model="value" placeholder="请选择">
                <el-option v-for="item in sjgls" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </div>
            <div class="add-scroll-item">
              <span class="span">关联项</span>
              <el-select v-model="value" placeholder="请选择">
                <el-option v-for="item in glxs" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </div>
            <div class="add-scroll-item">
              <span class="span">数据共享</span>
              <el-select v-model="value" placeholder="请选择">
                <el-option v-for="item in sjgxs" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="add-scroll-title">
            <span>动作</span>
          </div>
          <div class="add-scroll-button">
            <el-button type="primary">增加</el-button>
          </div>
          <div class="add-scroll-table">
            <el-table :data="tableData" stripe border height="100%">
            <el-table-column prop="name" label="床位">
            </el-table-column>
            <el-table-column prop="address" label="姓名">
            </el-table-column>
            <el-table-column prop="address" label="性别">
            </el-table-column>
            <el-table-column prop="address" label="年龄">
            </el-table-column>
            <el-table-column prop="address" label="住院号">
            </el-table-column>
            <el-table-column label="操作">
              <template>
                <el-button type="primary" size="small">编辑</el-button>
                <el-button type="danger" size="small">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
          </div>
        </div>
        <div class="add-button">
          <el-button type="primary">保存</el-button>
          <el-button type="primary" plain>取消</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      input: '',
      bbs: [
        { value: '1', label: '记录单' },
        { value: '2', label: '评分' }
      ],
      glkss: [
        { value: '1', label: '外科' },
        { value: '2', label: '内科' },
        { value: '3', label: '骨科' },
        { value: '4', label: '肿瘤科' },
        { value: '5', label: '耳鼻喉科' }
      ],
      value: '',
      checkList: [],
      checked: false,
      show: true,
      show1: true,
      show2: true,
      treeData: [
        { id: 1, select: false, label: '病区' },
        { id: 2, select: false, label: '床号' },
        { id: 3, select: false, label: '姓名' }
      ],
      treeData1: [
        { id: 1, select: false, label: '病区' },
        { id: 2, select: false, label: '床号' },
        { id: 3, select: false, label: '姓名' }
      ],
      treeData2: [
        { id: 1, select: false, label: '病区' },
        { id: 2, select: false, label: '床号' },
        { id: 3, select: false, label: '姓名' }
      ],
      lxs: [
        { value: '1', label: 'TextBox' },
        { value: '2', label: 'check' }
      ],
      cszt: [
        { value: '1', label: 'show' },
        { value: '2', label: 'hide' }
      ],
      sjlxs: [
        { value: '1', label: 'string' },
        { value: '2', label: 'int' }
      ],
      sjgls: [
        { value: '1', label: 'Patientinfo' }
      ],
      glxs: [
        { value: '1', label: '姓名' },
        { value: '2', label: '床号' }
      ],
      sjgxs: [
        { value: '1', label: 'Node' }
      ],
      tableData: [
        {},
        {},
        {}
      ]
    }
  },
  methods: {
    add () {
      const id = this.treeData[this.treeData.length - 1].id
      const newId = id + 1
      const obj = { id: newId, select: false, label: '' }
      this.treeData.push(obj)
    },
    update (item, index) {
      item.select = true
      this.$nextTick(() => {
        this.$refs['ref' + index][0].focus()
      })
    },
    del (id) {
      this.treeData = this.treeData.filter(item => item.id !== id)
    },
    down (id, index) {
      console.log(id, index)
      const treeData = JSON.parse(JSON.stringify(this.treeData))
      const arr = treeData[index + 1]
      treeData[index + 1] = treeData[index]
      treeData[index] = arr
      this.treeData = treeData
      console.log(treeData)
    },
    up (id, index) {
      console.log(id, index)
      const treeData = JSON.parse(JSON.stringify(this.treeData))
      const arr = treeData[index - 1]
      treeData[index - 1] = treeData[index]
      treeData[index] = arr
      this.treeData = treeData
      console.log(treeData)
    },
    blur (index) {
      if (!this.treeData[index].select) return
      this.treeData[index].select = false
    },
    close () {
      this.$parent.showAdd = false
    }
  }
}
</script>

<style lang="less" scoped>
.add {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 88;
  width: 100%;
  height: 100%;
  padding: 0 20px;
  padding-bottom: 10px;
  background-color: #fff;
  box-sizing: border-box;

  .add-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 60px;
    border-bottom: 1px solid rgba(0, 0, 0, .1);
    box-sizing: border-box;
    font-size: 22px;

    .el-button {
      margin: 0 20px;
    }
  }

  .add-search {
    display: flex;
    flex-wrap: wrap;
    height: 120px;

    .add-item {
      display: flex;
      align-items: center;
      margin: 0 10px;

      .span {
        margin-right: 10px;
        white-space: nowrap;
        font-size: 16px;
      }

      .select {
        font-size: 16px;
      }
    }
  }

  .add-box {
    display: flex;
    width: 100%;
    height: calc(100% - 180px);

    .add-left {
      width: 315px;
      height: 100%;
      padding: 10px;
      border: 1px solid rgba(242, 242, 242, 1);
      box-sizing: border-box;
      border-radius: 8px;
      box-shadow: 0px 0px 15px rgba(242, 242, 242, 1);
    }

    .add-right {
      width: calc(100% - 335px);
      height: 100%;
      margin-left: 30px;
      padding: 10px;
      border: 1px solid rgba(242, 242, 242, 1);
      box-sizing: border-box;
      border-radius: 8px;
      box-shadow: 0px 0px 15px rgba(242, 242, 242, 1);

      .add-button {
        justify-content: flex-start;
      }

      .add-scroll-title {
        width: 100%;
        height: 40px;
        line-height: 40px;
        border-bottom: 1px solid rgba(0, 0, 0, .1);
        box-sizing: border-box;
        font-size: 16px;
      }

      .add-scroll-box {
        display: flex;
        flex-wrap: wrap;
        width: 100%;

        .add-scroll-item {
          display: flex;
          align-items: center;
          margin: 5px 10px;

          .span {
            margin-right: 10px;
            white-space: nowrap;
            color: #999999;
            font-size: 16px;
          }
        }
      }

      .add-scroll-button {
        display: flex;
        justify-content: flex-end;
        width: 100%;
        height: 40px;
        margin: 10px 0;
        padding-right: 40px;
        box-sizing: border-box;
      }
    }

    .add-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 40px;
      font-size: 16px;
    }

    .add-scroll {
      overflow-y: scroll;
      width: 100%;
      height: calc(100% - 90px);

      &::-webkit-scrollbar {
        width: 0;
      }

      .tree {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 20px;

        .span {
          margin: 0 10px;
          font-size: 16px;
        }

        .icon {
          color: #d5d5d5;
          cursor: pointer;
        }
      }

      .tree-children {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: calc(100% - 70px);
        height: 60px;
        margin-left: 70px;

        .tree-text {
          width: 120px;

          /deep/.el-input__inner {
            border: none;
            font-size: 16px;
          }

          /deep/.el-input.is-disabled .el-input__inner {
            background-color: #fff;
            color: #000;
            font-size: 16px;
            cursor: auto;
          }
        }

        .tree-icon {
          display: none;
          margin: 0 10px;
          cursor: pointer;
          color: #d5d5d5;
          font-size: 20px;
        }
      }

      .tree-children:last-child .tree-left .tree-left-item:last-child {
        border-left: none;
      }

      .tree-children:hover .tree-icon {
        display: block;
      }
    }

    .add-button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 50px;
    }
  }
}
</style>
