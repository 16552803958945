<template>
  <div class="add">
    <div class="add-scroll">
      <div class="add-line">
        <div class="add-key">
          <span>眼睁反应（E）</span>
        </div>
        <el-radio-group v-model="radio">
          <el-radio label="1">自动睁眼（4分）</el-radio>
          <el-radio label="2">呼唤睁眼（3分）</el-radio>
          <el-radio label="3">刺痛睁眼（2分）</el-radio>
          <el-radio label="4">无反应（1分）</el-radio>
        </el-radio-group>
      </div>
      <div class="add-line">
        <div class="add-key">
          <span>语言反应（V）</span>
        </div>
        <el-radio-group v-model="radio">
          <el-radio label="1">回答正确（4分）</el-radio>
          <el-radio label="2">对打混乱（3分）</el-radio>
          <el-radio label="3">答非所问（2分）</el-radio>
          <el-radio label="4">含糊不清的声音（2分）</el-radio>
          <el-radio label="5">无反应（1分）</el-radio>
        </el-radio-group>
      </div>
      <div class="add-line">
        <div class="add-key">
          <span>运动反应（M）</span>
        </div>
        <el-radio-group v-model="radio">
          <el-radio label="1">正确执行命令（6分）</el-radio>
          <el-radio label="2">对刺激定位（分）</el-radio>
          <el-radio label="3">逃避刺激（4分）</el-radio>
          <el-radio label="4">刺痛屈曲（去皮层强直）（3分）</el-radio>
          <el-radio label="5">刺痛伸展（去大脑强直）（2分）</el-radio>
          <el-radio label="6">无反应（1分）</el-radio>
        </el-radio-group>
      </div>
      <div class="add-line">
        <div class="add-key4">
          <span>总分</span>
        </div>
        <el-input v-model="input" placeholder="请输入"></el-input>
      </div>
    </div>
    <div class="add-button">
      <el-button type="primary" @click="close">保存</el-button>
      <el-button @click="close">取消</el-button>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      input: '',
      value: '',
      options: [],
      radio: '',
      value1: '',
      textarea: ''
    }
  },
  methods: {
    close () {
      this.$parent.showAdd = false
    }
  }
}
</script>

<style lang="less" scoped>
.add {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 88;
  padding: 0 20px;
  background-color: #fff;
  box-sizing: border-box;

  .add-scroll {
    overflow-y: scroll;
    width: 100%;
    height: calc(100% - 60px);

    &::-webkit-scrollbar {
      width: 0;
    }
  }

  .add-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 60px;
  }

  .add-line {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 15px 0;

    .add-key {
      min-width: 100px;
      white-space: nowrap;
      color: #999;
      font-size: 16px;
    }

    .add-key1 {
      margin-right: 20px;
      white-space: nowrap;
      color: #999;
      font-size: 16px;
    }

    .add-key2 {
      margin-left: 40px;
    }

    .add-key3 {
      width: 140px;
    }

    .add-key4 {
      width: auto;
      margin-right: 10px;
      white-space: nowrap;
      color: #999;
      font-size: 16px;
    }

    .el-radio-group {
      width: calc(100% - 200px);
      flex-wrap: wrap;
    }

    .el-radio-group,
    .el-radio {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: 4px 0;
    }

    /deep/.el-radio__label {
      font-size: 16px;
    }

    /deep/.el-textarea__inner {
      width: 300px;
      margin-left: 10px;
      resize: none;
    }

    .el-input {
      width: 100px;
      margin-right: 20px;
    }
  }

  .add-line1 {
    padding-left: 50px;
    box-sizing: border-box;
  }
}
</style>
