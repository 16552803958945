<template>
  <div class="print">
    <div class="print-head">
      <span>打印设置</span>
      <el-button type="primary" @click="close">返回</el-button>
    </div>
    <div class="print-box">
      <div class="print-search">
        <div class="search-item">
          <span class="span">选择日期</span>
          <el-date-picker
            v-model="value1"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
          </el-date-picker>
        </div>
        <div class="search-item">
          <span class="span">时间状态</span>
          <el-select v-model="value" placeholder="请选择">
            <el-option
              v-for="item in times"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="search-item">
          <span class="span">打印状态</span>
          <el-select v-model="value" placeholder="请选择">
            <el-option
              v-for="item in prints"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="search-item">
          <span class="span">用药状态</span>
          <el-select v-model="value" placeholder="请选择">
            <el-option
              v-for="item in medications"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="search-item">
          <span class="span">排序状态</span>
          <el-select v-model="value" placeholder="请选择">
            <el-option
              v-for="item in sorts"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="search-item">
          <el-button type="primary">全部打印</el-button>
          <el-button type="primary">打印所选</el-button>
        </div>
      </div>
      <el-checkbox-group v-model="checkList" class="print-content">
        <div class="print-lable" v-for="(item, index) in 6" :key="index">
          <div class="label-head">
            <div class="label-line">
              <span>01</span>
              <span class="name">张然</span>
              <span>2024-05-24 静滴</span>
            </div>
            <div class="label-line">
              <span>男</span>
              <span>药品</span>
              <span>剂量</span>
            </div>
          </div>
          <div class="label-box">
            <div class="label-line">
              <span>NS</span>
              <span>100毫升</span>
            </div>
            <div class="label-line">
              <span>普司立针</span>
              <span>100毫升</span>
            </div>
          </div>
          <div class="label-footer">
            <div class="label-line">
              <span>QD</span>
              <div class="code"></div>
            </div>
            <div class="label-line">
              <span></span>
              <el-checkbox :label="index">index</el-checkbox>
            </div>
          </div>
        </div>
      </el-checkbox-group>
    </div>
    <div class="print-footer">
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="1"
        :page-sizes="[100, 200, 300, 400]" :page-size="100" layout="total, sizes, prev, pager, next, jumper"
        :total="400">
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      value: '',
      value1: '',
      times: [
        { label: '全部', value: '1' },
        { label: '今日标签', value: '2' },
        { label: '明日标签', value: '3' }
      ],
      prints: [],
      medications: [
        { label: '静滴', value: '1' },
        { label: '皮下注射', value: '2' },
        { label: '静推', value: '3' },
        { label: '肌注', value: '4' },
        { label: '口服', value: '5' }
      ],
      sorts: [
        { label: '按病人', value: '1' },
        { label: '按上下午', value: '2' }
      ],
      checkList: []
    }
  },
  mounted () {},
  methods: {
    handleSizeChange (val) {
      console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      console.log(`当前页: ${val}`)
    },
    close () {
      this.$parent.showPrint = false
    }
  }
}
</script>

<style lang="less" scoped>
.print {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 88;
  width: 100%;
  height: 100%;
  padding: 10px;
  background-color: #fff;
  box-sizing: border-box;

  .print-head {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 48px;
    padding-right: 40px;
    border-bottom: 1px solid rgba(0, 0, 0, .1);
    box-sizing: border-box;
    font-size: 20px;
  }

  .print-box {
    width: 100%;
    height: calc(100% - 120px);
    margin-top: 10px;

    .print-search {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      width: 100%;
      height: 120px;

      .search-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 0 20px;

        .span {
          margin-right: 10px;
          white-space: nowrap;
          color: #999;
          font-size: 14px;
        }
      }
    }

    .print-content {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      width: 100%;
      height: calc(100% - 120px);

      .print-lable {
        width: calc(33.3% - 20px);
        height: calc(50% - 20px);
        margin: 10px;
        padding: 20px;
        padding-top: 0;
        border-radius: 8px;
        border: 1px solid rgba(242, 242, 242, 1);
        box-shadow: 0px 0px 15px rgba(242, 242, 242, 1);
        box-sizing: border-box;

        &:hover {
          background-color: rgba(247, 247, 251, 1);
        }

        .label-head {
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          width: 100%;
          height: 35%;

          .label-line {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            font-size: 16px;

            .name {
              font-size: 22px;
            }
          }
        }

        .label-box {
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          width: 100%;
          height: 30%;
          border-top: 1px solid rgba(0, 0, 0, .1);
          border-bottom: 1px solid rgba(0, 0, 0, .1);
          box-sizing: border-box;

          .label-line {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            font-size: 16px;
          }
        }

        .label-footer {
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          width: 100%;
          height: 35%;

          .label-line {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            font-size: 16px;

            .code {
              width: 60%;
              height: 60px;
              background-color: #999;
            }

            /deep/.el-checkbox__label {
              display: none;
            }
          }
        }
      }
    }
  }

  .print-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 62px;
  }
}
</style>
