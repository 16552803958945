<template>
  <div class="run">
    <div class="run-title">
      <span>自定义体征项维护</span>
    </div>
    <div class="run-box">
      <div class="run-left">
        <div class="box-search">
          <div class="box-item">
            <span class="span">名称</span>
            <el-input v-model="input" placeholder="请输入"></el-input>
          </div>
          <el-button type="primary" @click="showAdd = true">新增</el-button>
        </div>
        <div class="box-title">
          <span>未设置</span>
        </div>
        <div class="box-content">
          <el-table :data="tableData" border stripe height="100%">
            <el-table-column type="selection" width="55">
            </el-table-column>
            <el-table-column prop="name" label="名称">
            </el-table-column>
            <el-table-column prop="name" label="单位">
            </el-table-column>
            <el-table-column prop="name" label="数据类型">
            </el-table-column>
            <el-table-column prop="name" label="编号">
            </el-table-column>
            <el-table-column prop="name" label="操作">
              <!-- slot-scope="scope" -->
              <template>
                <el-button type="danger" size="small">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="run-center">
        <el-button type="info">
          <span class="el-icon-d-arrow-right"></span>
        </el-button>
        <el-button type="info">
          <span class="el-icon-d-arrow-left"></span>
        </el-button>
      </div>
      <div class="run-right">
        <div class="box-search box-search1">
          <el-button type="primary">保存</el-button>
          <el-button type="primary">刷新</el-button>
        </div>
        <div class="box-title">
          <span>病区自定义体征</span>
        </div>
        <div class="box-content">
          <el-table :data="tableData" border stripe height="100%">
            <el-table-column type="selection" width="55">
            </el-table-column>
            <el-table-column prop="name" label="名称">
            </el-table-column>
            <el-table-column prop="name" label="编号">
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <Add v-if="showAdd"></Add>
  </div>
</template>

<script>
import Add from './add.vue'
export default {
  components: {
    Add
  },
  data () {
    return {
      value: '',
      input: '',
      tableData: [
        {},
        {},
        {}
      ],
      showAdd: false,
      showUpdate: false
    }
  },
  methods: {
    handleSizeChange (val) {
      console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      console.log(`当前页: ${val}`)
    },
    openAdd () {
      this.showAdd = true
    },
    openUpdate () {
      this.showUpdate = true
    }
  }
}
</script>

<style lang="less" scoped>
.run {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 10px;
  background-color: #fff;
  box-sizing: border-box;

  .run-title {
    width: 100%;
    height: 48px;
    line-height: 48px;
    padding-right: 40px;
    border-bottom: 1px solid rgba(0, 0, 0, .1);
    box-sizing: border-box;
    font-size: 20px;
  }

  .run-box {
    display: flex;
    width: 100%;
    height: calc(100% - 58px);
    margin-top: 10px;

    .run-left {
      width: calc(100% - 480px);
      height: 100%;
      padding: 10px;
      border: 1px solid rgba(242, 242, 242, 1);
      box-sizing: border-box;
      border-radius: 8px;
      box-shadow: 0px 0px 15px rgba(242, 242, 242, 1);
    }

    .run-center {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100px;
      height: 100%;
      padding-top: 150px;
      box-sizing: border-box;

      .el-button {
        margin: 0;
        margin-top: 20px;
      }
    }

    .run-right {
      width: 380px;
      height: 100%;
      padding: 20px;
      border: 1px solid rgba(242, 242, 242, 1);
      box-sizing: border-box;
      border-radius: 8px;
      box-shadow: 0px 0px 15px rgba(242, 242, 242, 1);
    }

    .box-title {
      width: 100%;
      height: 50px;
      line-height: 50px;
      font-size: 18px;
    }

    .box-search {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      height: 60px;

      .box-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 16px;

        .span {
          margin-right: 10px;
          white-space: nowrap;
        }
      }

      .el-button {
        margin: 0 20px;
      }
    }

    .box-search1 {
      justify-content: flex-end;
    }

    .box-content {
      overflow-y: scroll;
      width: 100%;
      height: calc(100% - 110px);

      &::-webkit-scrollbar {
        width: 0;
      }

      /deep/.el-table__cell {
        text-align: center;
      }
    }
  }
}
</style>
