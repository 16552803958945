<template>
  <div class="tab">
    <div class="tab-left">
      <div class="tab-item" :class="{ 'tab-active': index === tabIndex }" v-for="(item, index) in tabs" :key="index" @click="tabClick(index)">
        <span>{{ item }}</span>
      </div>
    </div>
    <div class="tab-right">
      <Son1 v-if="tabIndex === 0"></Son1>
      <Son2 v-else-if="tabIndex === 1"></Son2>
    </div>
  </div>
</template>

<script>
import Son1 from './son1/son1.vue'
import Son2 from './son2/son2.vue'
export default {
  components: {
    Son1,
    Son2
  },
  data () {
    return {
      tabIndex: 0,
      tabs: Object.freeze(['出入量记录单', '评估记录单']),
      options: [],
      radio: '',
      showEnter: false
    }
  },
  methods: {
    tabClick (index) {
      this.tabIndex = index
    }
  }
}
</script>

<style lang="less" scoped>
.tab {
  display: flex;
  width: 100%;
  height: 100%;

  .tab-left {
    width: 270px;
    height: 100%;

    .tab-item {
      width: 100%;
      height: 43px;
      line-height: 43px;
      margin-bottom: 30px;
      text-align: center;
      white-space: nowrap;
      border: 1px solid rgba(242, 242, 242, 1);
      border-radius: 8px;
      box-sizing: border-box;
      cursor: pointer;
      font-size: 16px;
    }

    .tab-active {
      background-color: rgba(37, 97, 239, 1);
      color: #fff;
    }
  }

  .tab-right {
    width: calc(100% - 290px);
    height: 100%;
    margin-left: 20px;
  }
}
</style>
